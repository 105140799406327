import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { RootState } from '../../store/rootReducer';
import { Notification, actions } from '../../store/notifications';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 10,
    left: 10,
    zIndex: 10000,
    width: 300,
  },
});

type Props = {
  data: Notification;
  onRemove: (id: string) => void;
};

const NotificationItem = memo((props: Props) => {
  const {
    data: { message, persist, id, type },
    onRemove,
  } = props;

  useEffect(() => {
    if (!persist && onRemove) {
      setTimeout(() => {
        onRemove(id);
      }, 5000);
    }
  }, [onRemove, persist, id]);

  return (
    <Alert variant="filled" severity={type} style={{ marginBottom: 8 }}>
      {message}
    </Alert>
  );
});

export const Notifications = memo(function Notifications() {
  const notifications = useSelector<RootState, Notification[]>(
    (state) => state.notifications
  );
  const dispatch = useDispatch();
  const styles = useStyles();
  const remove = useCallback(
    (id: string) => {
      dispatch(actions.remove(id));
    },
    [dispatch]
  );

  return (
    <div className={styles.root}>
      {notifications.map((item) => {
        return <NotificationItem key={item.id} data={item} onRemove={remove} />;
      })}
    </div>
  );
});
