import React, { FC } from 'react';
import { Page } from '../../layout/Page';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../store/requests';
import { actions, createTenantRequestName } from '../../store/tenants';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/Breadcrumbs';
import { Button, Grid, TextField } from '@material-ui/core';
import { Field, FieldProps, Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { createUserRequestName, Roles, User } from '../../store/user';
import { validationSchemasWithPassword } from '../../components/UserProfileForm';

export const getFieldTestId = (name: string) => `tenant-form-field-${name}`;
const getInputProps = (name: string) => ({
  'data-testid': getFieldTestId(name),
});
const breadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'Tenants',
    href: '/tenants',
  },
  {
    title: 'New Tenant',
  },
];

const useStyles = makeStyles(({ palette: { text: { primary } } }) => ({
  root: {
    maxWidth: 700,
    margin: '0 auto',
    '& input:disabled': {
      color: primary,
    },
    '& [aria-disabled]': {
      color: primary,
    },
    '& [class~="Mui-disabled"] svg': {
      visibility: 'hidden',
    },
  },
  submitButton: {
    marginRight: 8,
  },
}));

export const NewTenant: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isSaving = useSelector(
    isProcessingSelector([createTenantRequestName, createUserRequestName])
  );

  const data: User = {
    Username: '',
    UserAttributes: {
      email: '',
      name: '',
      phone_number: '',
      'custom:practice': '',
      'custom:specialty': '',
      tmppassword: '',
      'custom:tenant': '',
    },
    Groups: Roles.Admin,
  };

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Formik
        validateOnBlur={false}
        validationSchema={validationSchemasWithPassword}
        enableReinitialize
        initialValues={data}
        onSubmit={(values) => {
          dispatch(actions.create(values));
        }}
      >
        <Grid item xs={12}>
          <Form className={styles.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field name="UserAttributes.custom:tenant">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      value={field.value || ''}
                      error={!!meta.error}
                      helperText={meta.error}
                      label="Tenant Name"
                      inputProps={getInputProps(field.name)}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="Username">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      value={field.value || ''}
                      error={!!meta.error}
                      helperText={meta.error}
                      label="UserName"
                      inputProps={getInputProps(field.name)}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="UserAttributes.tmppassword">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      type="password"
                      value={field.value || ''}
                      error={!!meta.error}
                      helperText={meta.error}
                      label="Password"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="UserAttributes.email">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      value={field.value || ''}
                      error={!!meta.error}
                      helperText={meta.error}
                      label="Email address"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="UserAttributes.phone_number">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      value={field.value || ''}
                      error={!!meta.error}
                      helperText={meta.error}
                      placeholder="+1 111 1111 111"
                      label="Phone number"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSaving}
                  className={styles.submitButton}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Formik>
    </Page>
  );
};
