import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { NavLink, useHistory } from 'react-router-dom';
import { Page } from '../../layout/Page';
import { TableFilters, TableFiltersColumn } from '../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { isProcessingSelector } from '../../store/requests';
import {
  actions,
  loadMoreOpNotesRequestName,
  loadOpNotesRequestName,
  OpNote,
} from '../../store/opNotes';
import formatDate from '../../utils/formatDate';
import { CPTCode } from '../../store/cptCodes';
import {
  SearchableOpNoteFilterInput,
  SearchableSortDirection,
} from '../../API';
import { usePermission } from '../../components/Permission';
import { ADMINS, APPROVERS, Roles, SUPERS } from '../../store/user';
import { FormikValues } from 'formik';
import {
  actions as tenantsActions,
  loadUserProfileTenantsRequestName,
} from '../../store/tenants';
import useUserRoleChecker from '../../hooks/useUserRoleChecker';

const initialSort = {
  field: 'createdAt',
  dir: SearchableSortDirection.desc,
};

const filterableFields = ['id', 'text', 'updatedBy'];

const useStyles = makeStyles({
  text: {
    maxHeight: 48,
    overflow: 'hidden',
  },
});

export const OpNotes: FC = () => {
  const styles = useStyles();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const username = useSelector(
    (state: RootState) => state.user.data?.Username ?? ''
  );
  const isDataEntry = useUserRoleChecker([Roles.DataEntry]);
  const tenantId = useSelector((state: RootState) => state.user.tenantId);
  const isCreatedByVisible = usePermission([...ADMINS, ...APPROVERS]);
  const storeItems = useSelector(
    (state: RootState) => state.opNotes.data.listOpNotes?.items ?? []
  );
  const tenants = useSelector(
    (state: RootState) => state.tenants.userProfileTenants
  );
  const isLoading = useSelector(
    isProcessingSelector([
      loadOpNotesRequestName,
      loadUserProfileTenantsRequestName,
    ])
  );
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreOpNotesRequestName])
  );
  const hasMore = useSelector(
    (state: RootState) => !!(state.opNotes.data.listOpNotes?.nextToken ?? null)
  );
  const isSuperUser = useUserRoleChecker(SUPERS);
  const items = useMemo(() => {
    if (isSuperUser) {
      const tenantsMap = new Map(tenants.map((item) => [item.id, item.name]));

      return storeItems.map((item) => ({
        ...item,
        tenant: item.tenantId ? tenantsMap.get(item.tenantId) : item.tenantId,
      }));
    }

    return storeItems;
  }, [storeItems, tenants, isSuperUser]);
  const handleAdd = useCallback(() => {
    push('/opNotes/new');
  }, [push]);
  const handleUpload = useCallback(() => {
    push('/opNotes/upload');
  }, [push]);
  const getFilters = useCallback(
    (values: FormikValues) => {
      const keys = Object.keys(values);
      let res: SearchableOpNoteFilterInput = {
        and: keys
          .filter((itemKey) => itemKey !== 'search')
          .reduce<SearchableOpNoteFilterInput[]>((res, key) => {
            const value = values[key];

            if (value) {
              return [
                ...res,
                {
                  or: [
                    {
                      [key]: {
                        match: value,
                      },
                    },
                    {
                      [key]: {
                        matchPhrasePrefix: value,
                      },
                    },
                  ],
                },
              ];
            }

            return res;
          }, []),
      };

      if (!res?.and?.length) {
        delete res.and;
      }

      if (values.search) {
        res = {
          ...res,
          or: [
            ...filterableFields.reduce<SearchableOpNoteFilterInput[]>(
              (res, itemKey) => {
                return [
                  ...res,
                  {
                    [itemKey]: {
                      matchPhrasePrefix: values.search,
                    },
                  },
                  {
                    [itemKey]: {
                      match: values.search,
                    },
                  },
                ];
              },
              []
            ),
          ],
        };
      }

      if (tenantId) {
        res.and = [
          ...(res.and || []),
          {
            tenantId: {
              eq: tenantId,
            },
          },
        ];
      }

      if (isDataEntry) {
        res.and = [
          ...(res.and || []),
          {
            owner: {
              eq: isDataEntry ? username : (values.search as string),
            },
          },
        ];
      } else if (values.search) {
        const value = isDataEntry ? username : (values.search as string);

        res.or = [
          ...(res.or || []),
          {
            owner: {
              match: value,
            },
          },
          {
            owner: {
              matchPhrasePrefix: value,
            },
          },
        ];
      }

      return res;
    },
    [isDataEntry, username, tenantId]
  );

  useEffect(() => {
    if (isSuperUser) {
      dispatch(tenantsActions.loadUserTenants());
    }
  }, [dispatch, isSuperUser]);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          search
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          initialSort={initialSort}
          initialValues={{}}
          onSubmit={useCallback(
            (values, sort) => {
              const res = getFilters(values);
              dispatch(
                actions.load({
                  sort,
                  filter: res?.and?.length || res?.or?.length ? res : null,
                })
              );
            },
            [dispatch, getFilters]
          )}
          onSubmitMore={useCallback(
            (values, sort) => {
              const res = getFilters(values);
              dispatch(
                actions.loadMore({
                  sort,
                  filter: res?.and?.length || res?.or?.length ? res : null,
                })
              );
            },
            [dispatch, getFilters]
          )}
          loading={isLoading}
          columns={[
            {
              label: 'ID',
              name: 'id',
              render: (data: OpNote) => (
                <NavLink title={data.id} to={`/opNotes/${data.id}`}>
                  {data.id.split('-')[0]}
                </NavLink>
              ),
              filter: {
                name: 'id',
                type: 'text',
              },
              sort: true,
            },
            {
              label: 'Created',
              name: 'createdAt',
              format: formatDate,
              sort: true,
            },
            ...(isCreatedByVisible
              ? ([
                {
                  label: 'Created By',
                  name: 'owner',
                  filter: {
                    name: 'owner',
                    type: 'text',
                  },
                },
              ] as TableFiltersColumn[])
              : []),
            {
              label: 'Updated',
              name: 'updatedAt',
              format: formatDate,
              sort: true,
            },
            {
              label: 'Updated By',
              name: 'updatedBy',
              sort: true,
              filter: {
                name: 'updatedBy',
                type: 'text',
              },
            },
            ...(isSuperUser
              ? [
                {
                  label: 'Tenant',
                  name: 'tenant',
                },
              ]
              : []),
            {
              label: 'OpNotes',
              name: 'text',
              render: (data: OpNote) => (
                <Typography className={styles.text} title={data.text}>
                  {data.text}
                </Typography>
              ),
              filter: {
                name: 'text',
                type: 'text',
              },
            },
            {
              label: 'CPT Codes',
              name: 'approvedCptCodes',
              format: (data) =>
                (data?.items ?? [])
                  .map((item: CPTCode) => item.cptCode.code)
                  .join(', '),
              /*render: (data) => {
                const items = data?.approvedCptCodes?.items ?? [];

                return items.map((item: CPTCode, index: number) => (
                  <>
                    <CPTCodeWithDescription
                      key={item.cptCode.code}
                      code={item.cptCode.code}
                    />
                    {index !== items.length - 1 && <>, </>}
                  </>
                ));
              },*/
            },
            {
              label: 'Approved By',
              name: 'approvedBy',
              render: (data: OpNote) => (
                <>
                  {Object.keys(
                    data.approvedCptCodes?.items?.reduce<{
                      [key: string]: string;
                    }>((res, item) => {
                      if (item.approvedBy) {
                        return {
                          ...res,
                          [item.approvedBy]: item.approvedBy,
                        };
                      }

                      return res;
                    }, {}) ?? {}
                  ).join(', ')}
                </>
              ),
            },
          ]}
          columnsData={items}
          actions={
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAdd}
                style={{ marginRight: 16, marginBottom: 16 }}
              >
                Add OpNote
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={handleUpload}
                startIcon={<CloudUploadIcon />}
                style={{ marginBottom: 16 }}
              >
                Upload
              </Button>
            </div>
          }

          colGroup={
            <colgroup>
              <col width={120} />
              <col width={120} />
              <col width={120} />
              {isSuperUser && <col width={120} />}
              {isCreatedByVisible && <col width={120} />}
              <col width={120} />
              <col width={220} />
              <col width={120} />
              <col width={120} />
            </colgroup>
          }
        />
      </Grid>
    </Page>
  );
};
