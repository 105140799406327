import React, { FC } from 'react';
import { Page } from '../../layout/Page';
import { createUserRequestName, Roles, User } from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../store/requests';
import { actions } from '../../store/user';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/Breadcrumbs';
import { UserProfileForm } from '../../components/UserProfileForm';
import { Grid } from '@material-ui/core';
import { RootState } from '../../store/rootReducer';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'Users',
    href: '/users',
  },
  {
    title: 'New User',
  },
];

export const CreateUser: FC = () => {
  const dispatch = useDispatch();
  const useRole = useSelector((state: RootState) => state.user.role);
  const currentUserTenant = useSelector(
    (state: RootState) => state.user.tenantId
  );
  const isSuperAdmin = useRole.includes(Roles.SuperAdmin);

  const data: User = {
    Username: '',
    UserAttributes: {
      email: '',
      name: '',
      phone_number: '',
      'custom:practice': '',
      'custom:specialty': '',
      tmppassword: '',
      'custom:tenant': isSuperAdmin ? '' : currentUserTenant || '',
    },
    Groups: Roles.DataEntry,
  };
  const isSaving = useSelector(isProcessingSelector([createUserRequestName]));

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <UserProfileForm
        initialValues={data}
        mode="create"
        disabled={isSaving}
        onSubmit={(values) => {
          dispatch(actions.create(values));
        }}
      />
    </Page>
  );
};
