import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { actions, User } from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ForgotPassword } from './components/ForgotPassword';
import { ChangePassword } from './components/ChnagePassword';
import { SignIn } from './components/SignIn';
import { SignUp } from './components/SignUp';

export type Screens = 'signIn' | 'signUp' | 'changePassword' | 'forgotPassword';

export type Context = {
  setScreen: (screen: Screens) => void;
};

export const AuthenticatorContext = createContext<Context>({
  setScreen: () => null,
});

const useStyles = makeStyles({
  root: {
    width: 380,
    padding: 20,
    margin: '0 auto',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1px 1px 4px 0px',
  },
});

export const Authenticator: FC = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [screen, setScreen] = useState<Screens>('signIn');
  const [authorizedUser, setAuthorizedUser] = useState<User | null>(null);
  const [initialised, setInitialised] = useState(false);

  const user = useSelector((state: RootState) => state.user.data);
  const userRole = useSelector((state: RootState) => state.user.role);

  const handleUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      dispatch(
        actions.setUser({
          Username: user.username,
          UserAttributes: Object.entries(user.attributes).reduce(
            (res, item) => {
              return {
                ...res,
                [item[0]]: item[1],
              };
            },
            {}
          ),
          Groups: '' as any,
        })
      );
      dispatch(actions.loadRole());
      dispatch(actions.loadTenantId());
    } catch (err) {
      setScreen('signIn');
      setInitialised(true);
      console.log('error getting user');
    }
  }, [dispatch]);

  useEffect(() => {
    handleUser();
  }, [handleUser]);
  useEffect(() => {
    if (userRole.length) {
      setInitialised(true);
    }
  }, [userRole]);

  const renderContent = () => {
    switch (screen) {
      case 'changePassword':
        return <ChangePassword user={authorizedUser} onSuccess={handleUser} />;
      case 'forgotPassword':
        return <ForgotPassword />;
      case 'signUp':
        return <SignUp />;
      default:
        return (
          <SignIn
            onSignedIn={(user) => {
              setAuthorizedUser(user);

              if (user.challengeName) {
                switch (user.challengeName) {
                  case 'NEW_PASSWORD_REQUIRED':
                    setScreen('changePassword');
                    return;
                }
              } else {
                handleUser();
              }
            }}
          />
        );
    }
  };

  if (!initialised) {
    return null;
  }

  if (user) {
    return <>{props.children}</>;
  }

  return (
    <div className={styles.root}>
      <AuthenticatorContext.Provider
        value={{
          setScreen,
        }}
      >
        {renderContent()}
      </AuthenticatorContext.Provider>
    </div>
  );
};
