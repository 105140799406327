import React, { FC, useEffect } from 'react';
import { Page } from '../../layout/Page';
import {
  loadUserProfileRequestName,
  loadUserRoleRequestName,
  updateUserRequestName,
} from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../store/requests';
import { actions } from '../../store/user';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/Breadcrumbs';
import { UserProfileForm } from '../../components/UserProfileForm';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import { CircularProgress, Grid } from '@material-ui/core';
import { NotFound } from '../NotFound';

const defaultBreadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'Users',
    href: '/users',
  },
  {
    title: 'User Profile',
  },
];

export type Props = {
  id?: string;
  mode?: 'create' | 'edit' | 'view';
  breadcrumbs?: BreadcrumbsItem[];
};

export const UserProfile: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { breadcrumbs = defaultBreadcrumbs, id: userId, mode = 'edit' } = props;
  const { id: paramsId } = useParams<{ id?: string }>();
  const id = userId || paramsId || '';
  const data = useSelector((state: RootState) => state.user.profile);
  const isSaving = useSelector(isProcessingSelector(updateUserRequestName));
  const isLoading =
    useSelector(
      isProcessingSelector([
        loadUserProfileRequestName,
        loadUserRoleRequestName,
      ])
    ) || (data?.Username ?? '') !== id;

  useEffect(() => {
    dispatch(actions.loadProfile(id));
  }, [id, dispatch]);

  if (!isLoading && !data) {
    return <NotFound />;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      {isLoading ? (
        <Grid container item xs={12} justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <UserProfileForm
          initialValues={data}
          mode={mode}
          disabled={isSaving}
          onSubmit={(values) => {
            if (values) {
              dispatch(actions.updateProfile(values));
            }
          }}
        />
      )}
    </Page>
  );
};
