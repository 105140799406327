import React, {
  FC,
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { actions } from '../../../../store/cptCodes';

export type Props = {
  onSelect: (value: string) => void;
};

export const SuggestionModes: FC<Props> = memo((props) => {
  const { onSelect } = props;
  const dispatch = useDispatch();
  const data = useSelector(
    (state: RootState) => state.cptCodes.suggestionModes
  );
  const [mode, setMode] = useState<string | void>('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setMode(e.target.value);
    },
    [setMode]
  );

  useEffect(() => {
    setMode(data[0] || '');
  }, [data, setMode]);
  useEffect(() => {
    if (!data.length) {
      dispatch(actions.loadSuggestionModes());
    }
  }, [data, dispatch]);
  useEffect(() => {
    if (mode) {
      onSelect(mode);
    }
  }, [mode, onSelect]);

  return data.length ? (
    <FormControl style={{ marginRight: 16 }}>
      <FormLabel>Suggestion Mode:</FormLabel>
      <Select value={mode} onChange={handleChange as any}>
        {data.map((mode) => (
          <MenuItem key={mode} value={mode}>
            {mode}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <CircularProgress />
  );
});
