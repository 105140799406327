import React, { useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Check } from '@material-ui/icons';
import { Page } from '../../layout/Page';
import { TableFilters } from '../../components/Table';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import {
  actions,
  loadTenantsRequestName,
  loadMoreTenantsRequestName,
  TenantItem,
} from '../../store/tenants';
import { isProcessingSelector } from '../../store/requests';
import { FormikValues } from 'formik';
import { ModelTenantFilterInput } from '../../API';
import formatDate from '../../utils/formatDate';
import { ToggleTenant } from './components/ToggleTenant';
import { DeleteTenant } from './components/DeleteTenant';
import { makeStyles } from '@material-ui/core/styles';

const getFilters = (values: FormikValues) => {
  const keys = Object.keys(values);
  let res: ModelTenantFilterInput = {
    and: keys.reduce<ModelTenantFilterInput[]>((res, key) => {
      const value = values[key];

      if (value) {
        return [
          ...res,
          {
            or: [
              {
                [key]: {
                  contains: value,
                },
              },
              {
                [key]: {
                  eq: value,
                },
              },
            ],
          },
        ];
      }

      return res;
    }, []),
  };

  if (!res?.and?.length) {
    delete res.and;
  }

  return Object.keys(res).length ? res : null;
};

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      marginLeft: 8,
    },
  },
});

export const Tenants = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const isLoading = useSelector(isProcessingSelector([loadTenantsRequestName]));
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreTenantsRequestName])
  );
  const items = useSelector(
    (state: RootState) => state.tenants.data.listTenants?.items ?? []
  );
  const hasMore = useSelector(
    (state: RootState) => !!state.tenants.data.listTenants?.nextToken ?? false
  );

  const handleAdd = useCallback(() => {
    push('/tenants/new');
  }, [push]);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          columns={[
            {
              name: 'id',
              label: 'ID',
              filter: {
                name: 'id',
              },
              render: (data: TenantItem) => (
                <NavLink title={data.id} to={`/tenants/${data.id}`}>
                  {data.id.split('-')[0]}
                </NavLink>
              ),
            },
            {
              name: 'name',
              label: 'Tenant Name',
              filter: {
                name: 'name',
              },
            },
            {
              name: 'createdBy',
              label: 'Created By',
              filter: {
                name: 'createdBy',
              },
            },
            {
              name: 'createdAt',
              label: 'Created At',
              format: formatDate,
            },
            {
              name: 'updatedBy',
              label: 'Updated By',
              filter: {
                name: 'updatedBy',
              },
            },
            {
              name: 'updatedAt',
              label: 'Updated At',
              format: formatDate,
            },
            {
              name: 'active',
              label: 'Active',
              render: (data: TenantItem) => {
                return <Check color={data.active ? 'primary' : 'disabled'} />;
              },
            },
            {
              name: '',
              label: '',
              render: ({ id, active }: TenantItem) => {
                return (
                  <div className={styles.buttons}>
                    <ToggleTenant id={id} active={active} />
                    <DeleteTenant id={id} active={active} />
                  </div>
                );
              },
            },
          ]}
          columnsData={items}
          onSubmit={useCallback(
            (values) => {
              dispatch(
                actions.load({
                  filter: getFilters(values),
                })
              );
            },
            [dispatch]
          )}
          onSubmitMore={useCallback(
            (values) => {
              dispatch(
                actions.loadMore({
                  filter: getFilters(values),
                })
              );
            },
            [dispatch]
          )}
          initialValues={{}}
          loading={isLoading}
          actions={
            <Button color="primary" variant="contained" onClick={handleAdd}>
              Add Tenant
            </Button>
          }
        />
      </Grid>
    </Page>
  );
};
