import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, createUserRequestName, Roles, User } from '../../../../store/user';
import { isProcessingSelector } from '../../../../store/requests';
import { Page } from '../../../../layout/Page';
import { SignUpForm } from './components'


export const SignUp: FC = () => {
  const dispatch = useDispatch();
  const data: User = {
    Username: '',
    UserAttributes: {
      email: '',
      name: '',
      phone_number: '',
      'custom:practice': '',
      'custom:specialty': '',
      tmppassword: '',
      'custom:tenant': '',
    },
    Groups: Roles.DataEntry,
  };
  const isSaving = useSelector(isProcessingSelector([createUserRequestName]));

  return (
    <Page>
      <SignUpForm
        initialValues={data}
        mode="create"
        disabled={isSaving}
        onSubmit={(values) => {
          dispatch(actions.create(values));
        }}
      />
    </Page>
  );
};
