import React, { FC } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

export type Props = {
  loading: boolean;
} & ButtonProps;

export const LoadingButton: FC<Props> = (props) => {
  const { loading, children, ...rest } = props;

  return (
    <Button {...rest} disabled={rest.disabled || loading}>
      {loading ? <CircularProgress size={24} /> : children}
    </Button>
  );
};
