import { Sort } from '../components/Table';

export default (filters?: Object | null, sort?: Sort): string => {
  const pairs = Object.entries(filters || {}).filter(
    ([_, value]) => value !== undefined && value !== ''
  );

  const sortStringValue = `${
    sort ? `sortBy=${sort.field}&sortDir=${sort.dir}` : ''
  }`;
  const filtersStringValue = `${pairs.reduce((res, [key, value]) => {
    const stringValue = `${key}=${value}`;
    if (res.length > 0) {
      return `${res}&${stringValue}`;
    }
    return stringValue;
  }, '')}`;

  return `${filtersStringValue}${
    filtersStringValue.length > 0 && sortStringValue.length > 0 ? '&' : ''
  }${sortStringValue}`;
};
