import React, { useMemo, memo, FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { Roles } from '../../store/user';

export const usePermission = (roles: Roles[]) => {
  const role = useSelector((state: RootState) => state.user.role);

  return useMemo(() => {
    return role.some((item) => roles.includes(item));
  }, [role, roles]);
};

export type Props = {
  roles: Roles[];
};

export const Permission: FC<Props> = memo((props) => {
  const { roles } = props;
  const isAvailable = usePermission(roles);

  return isAvailable ? <>{props.children}</> : null;
});
