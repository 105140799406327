import { ADMINS, Roles } from './store/user';
import { ComponentType } from 'react';
import { OpNotes } from './screens/OpNotes';
import { ViewOpNote } from './screens/ViewOpNote';
import { NewOpNote } from './screens/NewOpNote';
import { Users } from './screens/Users';
import { UserProfile } from './screens/UserProfile';
import { CreateUser } from './screens/CreateUser';
import { MyProfile } from './screens/MyProfile';
import { Tenants } from './screens/Tenants';
import { NewTenant } from './screens/NewTenant';
import { EditTenant } from './screens/EditTenant';
// @ts-ignore
import { Feedback } from './screens/Feedback/Feedback';
import { FeedbackConfirmationPage } from './screens/Feedback/FeedbackConfirmationPage';
import { UploadOnNote } from './screens/UploadOpNote';

export type Route = {
  path: string;
  roles?: Roles[];
  exact: boolean;
  linkLabel?: string;
  component: ComponentType;
};

export const routes: Route[] = [
  // first item is home route
  {
    path: '/opNotes/new',
    exact: true,
    component: NewOpNote,
  },
  {
    path: '/opNotes/upload',
    exact: true,
    component: UploadOnNote,
  },
  {
    path: '/opNotes',
    exact: true,
    linkLabel: 'OpNotes',
    component: OpNotes,
  },
  {
    path: '/opNotes/:id',
    exact: true,
    component: ViewOpNote,
  },
  {
    path: '/users',
    exact: true,
    linkLabel: 'Users',
    roles: ADMINS,
    component: Users,
  },
  {
    path: '/users/new',
    exact: true,
    roles: ADMINS,
    component: CreateUser,
  },
  {
    path: '/users/:id',
    exact: true,
    roles: ADMINS,
    component: UserProfile,
  },
  {
    path: '/profile',
    exact: true,
    component: MyProfile,
  },
  {
    path: '/tenants',
    exact: true,
    component: Tenants,
    linkLabel: 'Tenants',
    roles: [Roles.SuperAdmin],
  },
  {
    path: '/tenants/new',
    exact: true,
    component: NewTenant,
    roles: [Roles.SuperAdmin],
  },
  {
    path: '/tenants/:id',
    exact: true,
    component: EditTenant,
    roles: [Roles.SuperAdmin],
  },
  {
    path: '/feedback',
    exact: true,
    linkLabel: 'Feedback',
    component: Feedback,
  },
  {
    path: '/feedback/confirm',
    exact: true,
    component: FeedbackConfirmationPage,
  },
];

export const getHomeRoute = () => routes[0];
