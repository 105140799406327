import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../../../store/requests';
import {
  actions,
  deleteTenantRequestName,
  toggleTenantRequestName,
} from '../../../../store/tenants';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import useToggle from '../../../../hooks/useToggle';

export type Props = {
  id: string;
  active: boolean;
};

export const DeleteTenant: FC<Props> = (props) => {
  const { id, active } = props;
  const dispatch = useDispatch();
  const { value: isOpen, close, open } = useToggle();
  const isLoading = useSelector(
    isProcessingSelector([deleteTenantRequestName, toggleTenantRequestName])
  );

  const handleToggle = useCallback(() => {
    dispatch(
      actions.delete({
        id,
        active,
      })
    );
  }, [active, id, dispatch]);
  const handleSubmit = useCallback(() => {
    handleToggle();
    close();
  }, [close, handleToggle]);

  if (active) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        disabled={isLoading}
        onClick={open}
        color="secondary"
      >
        Delete
      </Button>
      <ConfirmationDialog
        disabled={isLoading}
        open={isOpen}
        onClose={close}
        title="Warning!"
        inputConfirmationText="delete"
        text="Please note that all the Tenant’s data including the Users, On Notes, logs, in-progress data will be deleted and cannot be restored."
        onSubmit={handleSubmit}
      />
    </>
  );
};
