import { useEffect, useRef } from 'react';

export default function <T = any>(value: T): T {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
