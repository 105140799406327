import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import React, { PropsWithChildren, useState } from 'react';
import { isSuperUser, Roles } from '../../../../../store/user';
import { get } from 'lodash';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { TenantField } from '../../../../../components/UserProfileForm/components/TenantField';
import { BackToSignInLink } from '../../BackToSignInLink';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/rootReducer';


const phoneRegExp = /([+]\d{1,2}[.-/\s]?)(\d{3}[.-]?){2}\d{4}/;
const attributesSchema = {
  name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(35, 'Must be 35 characters or less'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Required'),
};
const commonFieldsSchema = {
  Username: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(35, 'Must be 35 characters or less')
    .required('Required'),
  Groups: Yup.string().required('Required'),
};

export const validationSchemasWithPassword = Yup.object({
  ...commonFieldsSchema,
  UserAttributes: Yup.object({
    ...attributesSchema,
    tmppassword: Yup.string().required('Required').min(8),
  }),
});

const useStyles = makeStyles(({ palette: { text: { primary } } }) => ({
  root: {
    maxWidth: 700,
    margin: '0 auto',
    '& input:disabled': {
      color: primary,
    },
    '& [aria-disabled]': {
      color: primary,
    },
    '& [class~="Mui-disabled"] svg': {
      visibility: 'hidden',
    },
  },
  submitButton: {
    marginRight: 8,
  },
}));

export type Props<T> = {
  initialValues: T;
  disabled?: boolean;
  mode: 'create' | 'edit' | 'view';
  onSubmit: (values: T) => void;
};

export const getFieldTestId = (name: string) =>
  `user-profile-form-field-${name}`;
const getInputProps = (name: string) => ({
  'data-testid': getFieldTestId(name),
});
export const submitButtonTestId = 'user-profile-form-submit';
export const editButtonTestId = 'user-profile-form-edit';

export function SignUpForm<T>(props: PropsWithChildren<Props<T>>) {
  const styles = useStyles();
  const { onSubmit, initialValues, disabled, mode } = props;
  const isSuperAdmin = true;
  const [isTenantRequired, setIstenantRequired] = useState(
    // @ts-ignore
    !isSuperUser(initialValues.Groups)
  );
  const [edit, setEdit] = useState(mode !== 'edit');
  const readOnly = mode === 'view' || !edit;
  const hasTenant = !!get(initialValues, 'UserAttributes.custom:tenant', false);
  const isSuperRolesVisible = !hasTenant && (isSuperAdmin || mode === 'view');
  const isRegularRolesVisible = !isSuperRolesVisible || mode === 'create';


  return (
    <Formik
      validateOnBlur={false}
      validationSchema={Yup.object({
        ...commonFieldsSchema,
        UserAttributes: Yup.object({
          ...attributesSchema,
          ...(isTenantRequired
            ? {
              'custom:tenant': Yup.string().required('Required'),
            }
            : {}),
          ...(mode === 'create'
            ? {
              tmppassword: Yup.string().required('Required').min(8),
            }
            : {}),
        }),
      })}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Grid item xs={12}>
        <Form className={styles.root}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Sign up</Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="Username">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="UserName"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="UserAttributes.name">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Name"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="UserAttributes.tmppassword">
                  {({ field, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      {...field}
                      type="password"
                      disabled={readOnly}
                      value={field.value || ''}
                      error={edit && !!meta.error}
                      helperText={edit && meta.error}
                      label="Password"
                      inputProps={getInputProps(field.name)}
                    />
                  )}
                </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="UserAttributes.email">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Email address"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="UserAttributes.phone_number">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    placeholder="+1 111 1111 111"
                    label="Phone number"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="UserAttributes.custom:practice">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Name of practice"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="UserAttributes.custom:specialty">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    {...field}
                    disabled={readOnly}
                    value={field.value || ''}
                    error={edit && !!meta.error}
                    helperText={edit && meta.error}
                    label="Specialty"
                    inputProps={getInputProps(field.name)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="Groups">
                {(fieldProps: FieldProps) =>
                  fieldProps.field.value && (
                    <FormControl fullWidth>
                      <InputLabel>Role</InputLabel>
                      <Select
                        {...fieldProps.field}
                        onChange={(e) => {
                          fieldProps.field.onChange(e);
                          setIstenantRequired(
                            !isSuperUser(e.target.value as any)
                          );
                        }}
                        disabled={readOnly}
                        inputProps={getInputProps(fieldProps.field.name)}
                      >
                        {isSuperRolesVisible && (
                          <MenuItem value={Roles.SuperAdmin}>
                            SuperAdmin
                          </MenuItem>
                        )}
                        {isSuperRolesVisible && (
                          <MenuItem value={Roles.SuperApprover}>
                            SuperApprover
                          </MenuItem>
                        )}
                        {isRegularRolesVisible && (
                          <MenuItem value={Roles.Admin}>Admin</MenuItem>
                        )}
                        {isRegularRolesVisible && (
                          <MenuItem value={Roles.Approver}>Approver</MenuItem>
                        )}
                        {isRegularRolesVisible && (
                          <MenuItem value={Roles.DataEntry}>DataEntry</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  )
                }
              </Field>
            </Grid>
          {isSuperAdmin && <TenantField disabled={mode !== 'create'} />}
          <Grid container justify="space-between" item xs={12}>
            <BackToSignInLink />
                <Button
                  disabled={disabled}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={styles.submitButton}
                  data-testid={submitButtonTestId}
                >
                  Submit
                </Button>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Formik>
  );
}
