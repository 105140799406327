import React, { ChangeEvent, FC, memo, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core';

export type Props = {
  open: boolean;
  text?: string;
  title?: string;
  disabled?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  cancelText?: string;
  submitText?: string;
  inputConfirmationText?: string;
};

export const submitButtonTestId = 'confirmation-dialog-submit';
export const cancelButtonTestId = 'confirmation-dialog-cancel';
export const textTestId = 'confirmation-dialog-text';

export const ConfirmationDialog: FC<Props> = memo((props) => {
  const {
    open,
    title,
    onClose,
    onSubmit,
    disabled,
    inputConfirmationText,
    text = 'Are you sure?',
    cancelText = 'Cancel',
    submitText = 'Ok',
  } = props;

  const [confirmationText, setConfirmationText] = useState('');
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setConfirmationText(e.target.value);
    },
    [setConfirmationText]
  );
  const isNotValid =
    !!inputConfirmationText &&
    inputConfirmationText.toLowerCase() !== confirmationText.toLowerCase();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {title && <Typography variant="h5">{title}</Typography>}
        <Typography data-testid={textTestId}>{text}</Typography>
        {inputConfirmationText && (
          <Box pt={1}>
            <TextField
              value={confirmationText}
              onChange={handleChange}
              placeholder={`Please type "${inputConfirmationText}"`}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disabled}
          onClick={onClose}
          variant="contained"
          color="primary"
          data-testid={cancelButtonTestId}
        >
          {cancelText}
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="secondary"
          data-testid={submitButtonTestId}
          disabled={disabled || isNotValid}
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
