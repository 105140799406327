export enum Roles {
  Approver = 'Approver',
  Admin = 'Admin',
  DataEntry = 'DataEntry',
  SuperAdmin = 'SuperAdmin',
  SuperApprover = 'SuperApprover',
}

export const ADMINS = [Roles.Admin, Roles.SuperAdmin];
export const APPROVERS = [Roles.Approver, Roles.SuperApprover];
export const SUPERS = [Roles.SuperAdmin, Roles.SuperApprover];

export const isAdmin = (role: Roles[]) => {
  return ADMINS.some((item) => role.includes(item));
};
export const isApprover = (role: Roles[]) => {
  return APPROVERS.some((item) => role.includes(item));
};
export const isSuperUser = (role: Roles) =>
  SUPERS.some((item) => item === role);
