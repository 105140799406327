import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  content: {
    zIndex: 200,
    position: 'absolute',
    background: '#fff',
  },
  wrapper: ({
    isExpanded,
    collapsedHeight,
    openHeight,
  }: {
    isExpanded: boolean;
    collapsedHeight: number;
    openHeight: number;
  }) => ({
    maxHeight: isExpanded ? openHeight : collapsedHeight,
    overflow: isExpanded ? 'auto' : 'hidden',
  }),
  opener: {
    cursor: 'pointer',
    position: 'relative',

    '&::after': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      height: 1,
      width: '100%',
      content: '" "',
      zIndex: -1,
      backgroundColor: '#000',
    },

    '&:hover': {
      opacity: 0.2,
    },
  },
  icon: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    background: '#fff',
    boxShadow: '0 0 1px #000',
  },
});

export type Props = {
  maxHeight?: number;
};

export const Expandable: FC<Props> = (props) => {
  const { children, maxHeight = 48 } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [openHeight, setOpenHeight] = useState(0);
  const styles = useStyles({
    isExpanded,
    openHeight,
    collapsedHeight: maxHeight,
  });
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isExtraHeight, setIsExtraHeight] = useState(false);

  const handleResize = useCallback(() => {
    const hasExtraHeight = (contentRef.current?.offsetHeight ?? 0) > maxHeight;
    const contentTop = contentRef.current?.getBoundingClientRect().top ?? 0;

    setIsExtraHeight(hasExtraHeight);
    setOpenHeight(window.innerHeight - contentTop - 62);
  }, [contentRef, maxHeight, setIsExtraHeight, setOpenHeight]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, maxHeight]);
  useEffect(() => {
    if (!isExpanded && wrapperRef.current) {
      wrapperRef.current.scrollTop = 0;
    }
  }, [wrapperRef, isExpanded]);

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize, children]);

  return (
    <div style={{ height: maxHeight + 20, position: 'relative' }}>
      <div className={styles.content}>
        <div className={styles.wrapper} ref={wrapperRef}>
          <div ref={contentRef}>{children}</div>
        </div>
        {isExtraHeight && (
          <Grid
            container
            justify="center"
            className={styles.opener}
            onClick={() => setIsExpanded((value) => !value)}
          >
            <span className={styles.icon}>
              {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </span>
          </Grid>
        )}
      </div>
    </div>
  );
};
