import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Roles } from './roles';

export type UserState = {
  data: User | null;
  role: Roles[];
  tenantId: string;
  profile: User | null;
};

export type User = {
  Username: string;
  UserAttributes: { [key: string]: string };
  Groups: Roles;
  challengeName?: string;
};

const initialState: UserState = {
  data: null,
  role: [],
  tenantId: '',
  profile: null,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => ({
      ...state,
      data: action.payload,
    }),
    load: (state) => state,
    loadProfile: (state, action: PayloadAction<string>) => state,
    loadedProfile: (state, action: PayloadAction<User>) => ({
      ...state,
      profile: action.payload,
    }),
    updateProfile: (state, action: PayloadAction<User>) => state,
    updatedProfile: (state, action: PayloadAction<User>) => ({
      ...state,
      profile: action.payload,
    }),
    loadRole: (state) => state,
    loadedRole: (state, action: PayloadAction<Roles[]>) => ({
      ...state,
      role: action.payload,
    }),
    loadTenantId: (state) => state,
    loadedTenantId: (state, action: PayloadAction<string>) => ({
      ...state,
      tenantId: action.payload,
    }),
    create: (
      state,
      action: PayloadAction<User & { isTenantAdmin?: boolean }>
    ) => state,
    created: (state) => state,
    signOut: () => initialState,
  },
});

export const { actions, reducer } = slice;
