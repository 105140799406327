import React from 'react';
import { Page } from '../../layout/Page';
import { Typography } from '@material-ui/core';

export const NotFound = () => {
  return (
    <Page>
      <Typography align="center" variant="h3">
        Not Found
      </Typography>
    </Page>
  );
};
