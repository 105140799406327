import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import usePrevious from '../../../../hooks/usePrevious';
import { SearchableSortDirection } from '../../../../API';
import { Filters, Props as FiltersProps } from '../../../Filters';
import {
  CircularProgress,
  Grid,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { TextFilter } from '../../../Filters/TextFilter';
import { FilterItem } from '../../../Filters/FilterItem';
import { Sort, Table, TableFiltersColumn } from '../../Table';
import { FormikValues } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

export type TableFiltersContentProps<T> = {
  columns: TableFiltersColumn[];
  initialSort?: Sort;
  search?: boolean;
  actions?: ReactElement;
  columnsData: {
    [key: string]: any;
  }[];
  singleColumnFilter?: boolean;
  loading?: boolean;
  colGroup?: ReactElement;
  hasMore: boolean;
  loadMore: () => void;
  loadingMore: boolean;
  onSubmit: (values: FormikValues, sort?: Sort) => void;
} & Omit<FiltersProps<T>, 'onSubmit'>;

const switchSortDirection = (currentDir: SearchableSortDirection) => {
  return currentDir === SearchableSortDirection.asc
    ? SearchableSortDirection.desc
    : SearchableSortDirection.asc;
};

const useStyles = makeStyles({
  content: {
    overflow: 'auto',
  },
});

export function TableFiltersContent<T>(props: TableFiltersContentProps<T>) {
  const {
    columns,
    columnsData,
    actions,
    search,
    colGroup,
    loading,
    initialValues = {},
    initialSort,
    onSubmit,
    hasMore,
    loadMore,
    loadingMore,
    singleColumnFilter,
  } = props;
  const styles = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const loadingStateRef = useRef(false);
  const hasMoreStateRef = useRef(hasMore);
  const [sort, setSort] = useState<Sort | undefined>(initialSort);
  const [values, setValues] = useState<any>({});
  const previousSort = usePrevious(sort);
  const previousValues = usePrevious(values);

  const handleSort = useCallback(
    (field: string) => {
      setSort((value) => ({
        field,
        dir:
          value?.field === field
            ? switchSortDirection(value.dir)
            : SearchableSortDirection.asc,
      }));
    },
    [setSort]
  );

  useEffect(() => {
    if (
      (previousSort && sort !== previousSort) ||
      (previousValues && values !== previousValues)
    ) {
      onSubmit(values, sort);
    }
  }, [sort, previousSort, values, previousValues, onSubmit]);
  useEffect(() => {
    const scrollEl = scrollRef.current;
    const handler = (e: Event) => {
      const { target } = e;
      const el = target as HTMLDivElement;

      if (
        hasMoreStateRef.current &&
        !loadingStateRef.current &&
        Math.ceil(el.scrollTop + el.offsetHeight) + 5 >= el.scrollHeight
      ) {
        loadingStateRef.current = true;
        loadMore();
      }
    };

    scrollEl?.addEventListener('scroll', handler, false);

    return () => {
      scrollEl?.removeEventListener('scroll', handler);
    };
  }, [scrollRef, loadingStateRef, hasMoreStateRef, loadMore]);
  useEffect(() => {
    loadingStateRef.current = false;
  }, [columnsData]);
  useEffect(() => {
    hasMoreStateRef.current = hasMore;
  }, [hasMore]);

  useLayoutEffect(() => {
    if (
      !loading &&
      !loadingMore &&
      scrollRef.current &&
      hasMore &&
      !loadingStateRef.current &&
      scrollRef.current.offsetHeight + 24 >= scrollRef.current.scrollHeight
    ) {
      loadingStateRef.current = true;
      loadMore();
    }
  }, [loading, loadingMore, hasMore, loadMore, hasMoreStateRef]);

  return (
    <Filters
      singleColumnFilter={singleColumnFilter}
      initialValues={{ search: '', ...initialValues }}
      onSubmit={setValues}
    >
      <Grid container item xs={12}>
        {actions && (
          <Grid item xs={6}>
            {actions}
          </Grid>
        )}
        {search && (
          <Grid justify="flex-end" container item xs={6}>
            <TextFilter name="search" placeholder="Search" />
          </Grid>
        )}
      </Grid>
      <Grid ref={scrollRef} className={styles.content} item xs={12}>
        <Table stickyHeader>
          {colGroup}
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <TableCell key={index}>
                  {col.sort ? (
                    <TableSortLabel
                      direction={sort?.dir.toLowerCase() as any}
                      active={sort?.field === col.name}
                      onClick={() => handleSort(col.name)}
                    >
                      {col.label}
                    </TableSortLabel>
                  ) : (
                    col.label
                  )}
                  {col.filter && <FilterItem options={col.filter} />}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Grid container item xs={12} justify="center">
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              columnsData.map((data, index) => (
                <TableRow key={index}>
                  {columns.map((col) => {
                    const value = data[col.name];
                    let content = value;
                    if (col.format) {
                      content = col.format(value);
                    } else if (col.render) {
                      content = col.render(data);
                    }
                    return (
                      <TableCell key={`${index}-${col.name}`}>
                        {content}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            {hasMore && !loading && (
              <TableRow
                style={{ visibility: loadingMore ? 'visible' : 'hidden' }}
              >
                <TableCell align="center" colSpan={columns.length}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </Grid>
    </Filters>
  );
}
