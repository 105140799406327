import { all } from 'redux-saga/effects';
import { saga as opNotesSaga } from './opNotes';
import { saga as cptCodesSaga } from './cptCodes';
import { saga as userSaga } from './user';
import { saga as usersSaga } from './users';
import { saga as tenantsSaga } from './tenants';

export default function* rootSaga() {
  yield all([
    opNotesSaga(),
    cptCodesSaga(),
    userSaga(),
    usersSaga(),
    tenantsSaga(),
  ]);
}
