import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, TextField, Grid, FormLabel } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { useDispatch, useSelector } from 'react-redux';
import { actions, createOpNoteRequestName } from '../../store/opNotes';
import { isProcessingSelector } from '../../store/requests';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const regExp = /\S/;
const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: '/opNotes',
    title: 'OpNotes',
  },
  {
    title: 'Enter new OpNote',
  },
];

export const NewOpNote: FC = () => {
  const [note, setNote] = useState('');
  const { push } = useHistory();
  const isProcessing = useSelector(
    isProcessingSelector([createOpNoteRequestName])
  );
  const dispatch = useDispatch();
  const isValidNote = useMemo(() => {
    return regExp.test(note);
  }, [note]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setNote(e.target.value);
    },
    [setNote]
  );
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      dispatch(
        actions.create({
          input: { text: note.trim() },
        })
      );
    },
    [note, dispatch]
  );

  return (
    <Page>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justify="space-between">
              <Breadcrumbs items={breadcrumbs} />
              <div>

                <Button
                  color="default"
                  variant="contained"
                  onClick={() => push('/OpNotes/upload')}
                  startIcon={<CloudUploadIcon />}
                  style={{ marginRight: 16 }}
                >
                  Upload
                </Button>
                <Button
                  disabled={isProcessing || !isValidNote}
                  type="submit"
                  color="default"
                  variant="contained"
                >
                  Submit
              </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Please enter OpNote:</FormLabel>
              <TextField
                fullWidth
                multiline
                value={note}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Page>
  );
};
