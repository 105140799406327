import { takeLatest, call, put, delay, select } from 'redux-saga/effects';
import { actions } from './cptCodes.slice';
import requestSaga, { Request } from '../requestSaga';
import {
  SubmitOpNoteCptCodesMutationVariables,
  ApproveOpNoteCptCodesMutationVariables,
  SearchCptCodesQueryVariables,
} from '../../API';
import { CPTCode } from '../cptCodes';
import { RootState } from '../rootReducer';
import { User } from '../user';
import { listCptSuggestionModes } from '../../graphql/queries';
import { forwardTo } from '../../history';

export const loadCPTCodesRequestName = 'loadCPTCodesRequest';
function* loadCPTCodesSaga(action: ReturnType<typeof actions.search>) {
  yield delay(500);
  const variables: SearchCptCodesQueryVariables = {
    filter: {
      or: [
        {
          code: {
            matchPhrasePrefix: action.payload,
          },
        },
        {
          description: {
            match: action.payload,
          },
        },
      ],
    },
    limit: 20,
  };
  const response: Request<{
    searchCptCodes: { items: { code: string; description: string }[] };
  }> = yield call(requestSaga, {
    operation: /* GraphQL */ `
      query SearchCptCodes(
        $filter: SearchableCptCodeFilterInput
        $sort: SearchableCptCodeSortInput
        $limit: Int
        $nextToken: String
        $from: Int
      ) {
        searchCptCodes(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
        ) {
          items {
            code
            description
          }
          nextToken
          total
        }
      }
    `,
    name: loadCPTCodesRequestName,
    variables: action.payload ? variables : undefined,
  });

  if (response && response.data) {
    yield put(
      actions.loaded(
        response.data.searchCptCodes.items.map((item) => ({
          cptCode: item,
        }))
      )
    );
  }
}

export const loadApprovedSuggestedOpNoteCptCodesRequestName =
  'loadApprovedSuggestedOpNoteCptCodes';
function* loadApprovedSuggestedOpNoteCptCodesSaga(
  action: ReturnType<typeof actions.loadOpNoteApprovedSuggestedCodes>
) {
  const response: Request<{
    listApprovedSuggestedOpNoteCptCodes: CPTCode[];
  }> = yield call(requestSaga, {
    operation: /* GraphQL */ `
      query ListApprovedSuggestedOpNoteCptCodes(
        $opNoteId: ID!
        $cptSuggestionSize: Int
        $cptSuggestionMode: String
      ) {
        listApprovedSuggestedOpNoteCptCodes(
          opNoteId: $opNoteId
          cptSuggestionSize: $cptSuggestionSize
          cptSuggestionMode: $cptSuggestionMode
        ) {
          cptCode {
            code
            description
          }
          linkType
          approvedBy
        }
      }
    `,
    name: loadApprovedSuggestedOpNoteCptCodesRequestName,
    variables: action.payload,
  });

  if (response && response.data) {
    yield put(
      actions.loadedOpNoteApprovedSuggestedCodes(
        response.data.listApprovedSuggestedOpNoteCptCodes
      )
    );
  }
}

export const approveCPTCodeRequestName = 'approveCPTCode';
function* approveCPTCodeSaga(action: ReturnType<typeof actions.approve>) {
  const variables: ApproveOpNoteCptCodesMutationVariables = {
    input: action.payload,
  };
  const user: User = yield select((state: RootState) => state.user.data);
  yield call(requestSaga, {
    operation: /* GraphQL */ `
      mutation ApproveOpNoteCptCodes(
        $input: OpNoteCptCodesApproveRejectInput!
      ) {
        approveOpNoteCptCodes(input: $input)
      }
    `,
    name: approveCPTCodeRequestName,
    variables: variables,
    successNotification: {
      message: 'Approved successfully',
    },
  });

  yield put(
    actions.approved({
      approver: user?.Username ?? '',
      codeIds: action.payload.cptCodeCodes || [],
    })
  );
}

export const submitCPTCodeRequestName = 'sumbitCPTCode';
function* submitCPTCodeSaga(action: ReturnType<typeof actions.submit>) {
  const variables: SubmitOpNoteCptCodesMutationVariables = {
    input: action.payload,
  };
  const user: User = yield select((state: RootState) => state.user.data);
  yield call(requestSaga, {
    operation: /* GraphQL */ `
      mutation SubmitOpNoteCptCodes(
        $input: OpNoteCptCodesApproveRejectInput!
      ) {
        submitOpNoteCptCodes(input: $input)
      }
    `,
    name: submitCPTCodeRequestName,
    variables: variables,
    successNotification: {
      message: 'Submitted',
    },
  });
}

export const rejectCPTCodeRequestName = 'rejectCPTCode';
function* rejectCPTCodeSaga(action: ReturnType<typeof actions.reject>) {
  const variables: ApproveOpNoteCptCodesMutationVariables = {
    input: action.payload,
  };
  yield call(requestSaga, {
    operation: /* GraphQL */ `
      mutation RejectOpNoteCptCodes($input: OpNoteCptCodesApproveRejectInput!) {
        rejectOpNoteCptCodes(input: $input)
      }
    `,
    name: rejectCPTCodeRequestName,
    variables: variables,
    successNotification: {
      message: 'Rejected successfully',
    },
  });

  yield put(actions.rejected(action.payload.cptCodeCodes || []));
}

export const assignCPTCodeRequestName = 'assignCPTCode';
function* assignCPTCodeSaga(action: ReturnType<typeof actions.assign>) {
  const variables: ApproveOpNoteCptCodesMutationVariables = {
    input: {
      opNoteId: action.payload.opNoteId,
      cptCodeCodes: action.payload.cptCodes.map((item) => item.cptCode.code),
    },
  };
  const user: User = yield select((state: RootState) => state.user.data);
  yield call(requestSaga, {
    operation: /* GraphQL */ `
      mutation ApproveOpNoteCptCodes(
        $input: OpNoteCptCodesApproveRejectInput!
      ) {
        approveOpNoteCptCodes(input: $input)
      }
    `,
    name: assignCPTCodeRequestName,
    variables: variables,
    successNotification: {
      message: 'Assigned successfully',
    },
  });

  yield put(
    actions.assigned({
      codes: action.payload.cptCodes,
      approver: user?.Username ?? '',
    })
  );
}

export const loadSuggestionModesRequestName = 'loadSuggestionModesRequest';
function* loadSuggestionModesSaga() {
  const response: Request<{
    listCptSuggestionModes: string[];
  }> = yield call(requestSaga, {
    operation: listCptSuggestionModes,
    name: loadSuggestionModesRequestName,
  });

  if (response && response.data) {
    yield put(
      actions.loadedSuggestionModes(response.data.listCptSuggestionModes)
    );
  }
}

export const saga = function* ctpCodesSaga() {
  yield takeLatest(actions.search.type, loadCPTCodesSaga);
  yield takeLatest(actions.approve.type, approveCPTCodeSaga);
  yield takeLatest(actions.submit.type, submitCPTCodeSaga);
  yield takeLatest(actions.reject.type, rejectCPTCodeSaga);
  yield takeLatest(actions.assign.type, assignCPTCodeSaga);
  yield takeLatest(actions.loadSuggestionModes.type, loadSuggestionModesSaga);
  yield takeLatest(
    actions.loadOpNoteApprovedSuggestedCodes.type,
    loadApprovedSuggestedOpNoteCptCodesSaga
  );
};
