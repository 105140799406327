import React from 'react';
import { ScreenNavLink, useScreenNav } from '../ScreenNavLink';

export const useBackToSignInNav = () => {
  const setScreen = useScreenNav();

  return () => setScreen('signIn');
};

export const BackToSignInLink = () => (
  <ScreenNavLink screen="signIn" text="Back to Sign In" />
);
