import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { actions as userActions } from '../user';

export type Notification = {
  message: string;
  id: string;
  type: 'error' | 'warning' | 'success' | 'info';
  persist?: boolean;
};
export type NewNotification = Omit<Notification, 'id' | 'type'> &
  Partial<Pick<Notification, 'type'>>;
export type NotificationsState = Notification[];

const initialState: NotificationsState = [];

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<NewNotification>) => {
      return [
        ...state,
        {
          ...action.payload,
          id: v4(),
          type: action.payload.type || 'success',
        },
      ];
    },
    remove: (state, action: PayloadAction<string>) => {
      return state.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers: {
    [userActions.signOut.type]: () => initialState,
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
