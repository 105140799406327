import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { FileHeader } from './FileHeader';
import { FileError } from 'react-dropzone';

export interface UploadErrorProps {
  file: File;
  onDelete: (file: File) => void;
  errors: FileError[];
}

export function UploadError({ file, onDelete, errors }: UploadErrorProps) {
  return (
    <React.Fragment>
      <FileHeader file={file} onDelete={onDelete} />
      <LinearProgress variant="determinate" value={0} />
    </React.Fragment>
  );
}