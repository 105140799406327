import { actions } from '../tenants';
import { actions as userActions } from '../user';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { RootState } from '../rootReducer';
import { Tenants } from './tenants.slice';
import { forwardTo } from '../../history';
import requestSaga, { Request } from '../requestSaga';

const limit = 20;
const searchTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        createdBy
        updatedBy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const loadUserProfileTenantsRequestName = 'loadUserProfileTenants';
function* loadUserProfileTenantsSaga(
  action: ReturnType<typeof actions.loadUserTenants>
) {
  const res: Request<Tenants> = yield call(requestSaga, {
    operation: /* GraphQL */ `
      query ListTenants(
        $filter: ModelTenantFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            active
          }
        }
      }
    `,
    variables: {
      limit: 1000,
      filter: action.payload
        ? {
            active: {
              eq: true,
            },
          }
        : undefined,
    },
    name: loadUserProfileTenantsRequestName,
  });

  if (res && res.data) {
    yield put(actions.loadedUserTenants(res.data.listTenants.items));
  }
}

export const loadTenantsRequestName = 'loadTenants';
function* loadTenantsSaga(action: ReturnType<typeof actions.load>) {
  const res: Request<Tenants> = yield call(requestSaga, {
    operation: searchTenants,
    variables: {
      limit: limit,
      filter: action.payload?.filter,
      nextToken: null,
    },
    name: loadTenantsRequestName,
  });

  if (res && res.data) {
    yield put(actions.loaded(res.data));
  }
}

export const loadMoreTenantsRequestName = 'loadMoreTenants';
function* loadMoreTenantsSaga(action: ReturnType<typeof actions.loadMore>) {
  const nextToken = yield select(
    (state: RootState) => state.tenants.data.listTenants?.nextToken ?? null
  );
  const res: Request<Tenants> = yield call(requestSaga, {
    operation: searchTenants,
    variables: {
      limit,
      nextToken,
      filter: action.payload?.filter,
    },
    name: loadMoreTenantsRequestName,
  });

  if (res && res.data) {
    yield put(actions.loadedMore(res.data));
  }
}

export const createTenantRequestName = 'createTenant';
function* createTenantSaga(action: ReturnType<typeof actions.create>) {
  const response: Request<{ createTenant: { id: string } }> = yield call(
    requestSaga,
    {
      operation: /* GraphQL */ `
        mutation CreateTenant(
          $input: CreateTenantInput!
          $condition: ModelTenantConditionInput
        ) {
          createTenant(input: $input, condition: $condition) {
            id
            name
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,
      variables: {
        input: {
          name: action.payload.UserAttributes['custom:tenant'],
        },
      },
      successNotification: {
        message: 'Tenant has been created',
      },
      name: createTenantRequestName,
    }
  );

  if (response && response.data && response.data.createTenant) {
    yield put(
      userActions.create({
        ...{
          ...action.payload,
          UserAttributes: {
            ...action.payload.UserAttributes,
            'custom:tenant': response.data.createTenant.id,
          },
        },
        isTenantAdmin: true,
      })
    );
    yield take(userActions.created);
    yield forwardTo('/tenants');
  }
}

export const updateTenantRequestName = 'updateTenant';
function* updateTenantSaga(action: ReturnType<typeof actions.update>) {
  const response = yield call(requestSaga, {
    operation: /* GraphQL */ `
      mutation UpdateTenant(
        $input: UpdateTenantInput!
        $condition: ModelTenantConditionInput
      ) {
        updateTenant(input: $input, condition: $condition) {
          name
        }
      }
    `,
    variables: {
      input: action.payload,
    },
    successNotification: {
      message: 'Tenant has been updated',
    },
    name: updateTenantRequestName,
  });

  if (response && response.data.updateTenant) {
    yield forwardTo('/tenants');
  }
}
export const loadTenantRequestName = 'loadTenant';
function* loadTenantSaga(action: ReturnType<typeof actions.loadTenant>) {
  const response = yield call(requestSaga, {
    operation: /* GraphQL */ `
      query GetTenant($id: ID!) {
        getTenant(id: $id) {
          id
          name
          _version
        }
      }
    `,
    variables: {
      id: action.payload,
    },
    name: loadTenantRequestName,
  });

  if (response && response.data.getTenant) {
    yield put(actions.loadedTenant(response.data.getTenant));
  }
}

export const toggleTenantRequestName = 'toggleTenant';
function* toggleTenantSaga(action: ReturnType<typeof actions.toggle>) {
  const { active, id } = action.payload;
  const response: Request = yield call(requestSaga, {
    operation: active
      ? /* GraphQL */ `
          mutation DeactivateTenant($tenantId: ID!) {
            deactivateTenant(tenantId: $tenantId)
          }
        `
      : /* GraphQL */ `
          mutation ActivateTenant($tenantId: ID!) {
            activateTenant(tenantId: $tenantId)
          }
        `,
    variables: {
      tenantId: id,
    },
    successNotification: {
      message: active
        ? 'Tenant has been deactivated'
        : 'Tenant has been activated',
    },
    name: toggleTenantRequestName,
  });

  if (
    response &&
    (response.data.activateTenant || response.data.deactivateTenant)
  ) {
    yield put(
      actions.toggled({
        id,
        active: !active,
      })
    );
  }
}

export const deleteTenantRequestName = 'deleteTenant';
function* deleteTenantSaga(action: ReturnType<typeof actions.delete>) {
  const { id } = action.payload;

  const response: Request = yield call(requestSaga, {
    operation: /* GraphQL */ `
      mutation DeleteTenant($tenantId: ID!) {
        deleteTenant(tenantId: $tenantId)
      }
    `,
    variables: {
      tenantId: id,
    },
    successNotification: {
      message: 'Tenant has been deleted',
    },
    name: deleteTenantRequestName,
  });

  if (response && response.data.deleteTenant) {
    const requestParams = yield select(
      (state: RootState) => state.tenants.requestParams
    );

    yield put(actions.load(requestParams));
  }
}

export const saga = function* () {
  yield takeLatest(actions.load, loadTenantsSaga);
  yield takeLatest(actions.toggle, toggleTenantSaga);
  yield takeLatest(actions.create, createTenantSaga);
  yield takeLatest(actions.update, updateTenantSaga);
  yield takeLatest(actions.delete, deleteTenantSaga);
  yield takeLatest(actions.loadTenant, loadTenantSaga);
  yield takeLatest(actions.loadMore, loadMoreTenantsSaga);
  yield takeLatest(actions.loadUserTenants, loadUserProfileTenantsSaga);
};
