import { API, Auth, graphqlOperation } from 'aws-amplify';
import { put, call } from 'redux-saga/effects';
import { actions as requestActions } from './requests/requests.slice';
import {
  actions as notificationsActions,
  NewNotification,
} from './notifications';

export type RequestConfig = {
  name: string;
  successNotification?: NewNotification;
  errorNotification?: NewNotification;
  variables?: any;
  operation?: any;
  rest?: {
    url: string;
    apiName: string;
    data?: Object;
    method: 'get' | 'post' | 'put' | 'del';
  };
  propagateError?: boolean;
};

export type RequestError = {
  message: string;
  path: string | null;
};
export type Request<T = any> = {
  data: T | null;
  errors: RequestError[];
} | void;

export default function* requestSaga(config: RequestConfig) {
  const {
    name,
    rest,
    operation,
    variables,
    successNotification,
    errorNotification,
    propagateError,
  } = config;
  try {
    yield put(requestActions.start(name));
    let response: Request;
    if (operation) {
      response = yield call(
        [API, 'graphql'],
        graphqlOperation(operation, variables)
      );
    } else if (rest) {
      const session = yield call([Auth, 'currentSession']);
      response = yield call(
        // @ts-ignore
        [API, rest.method],
        rest.apiName,
        rest.url,
        {
          headers: {
            Authorization: session.getAccessToken().getJwtToken(),
          },
          ...rest.data,
        }
      );
    } else {
      console.error('Unknown API call');
      return;
    }

    yield put(requestActions.finish(name));

    if (successNotification) {
      yield put(
        notificationsActions.add({
          type: 'success',
          ...successNotification,
        })
      );
    }

    return response;
  } catch (e) {
    yield put(requestActions.finish(name));

    yield put(
      requestActions.error({
        name,
        error: String(e),
      })
    );
    console.log(e);
    if (propagateError) {
      throw Error(e);
    } else {
      if (errorNotification) {
        yield put(
          notificationsActions.add({
            type: 'error',
            ...errorNotification,
          })
        );
      } else {
        let message = String(e);

        if (e.response && e.response.data) {
          message = e.response.data.message;
        } else if (e.errors) {
          message = (e.errors as RequestError[])
            .reduce<string[]>((res, error) => [...res, error.message], [])
            .join(', ');
        }

        yield put(
          notificationsActions.add({
            type: 'error',
            message,
          })
        );
      }
    }
  }
}
