/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const approveOpNoteCptCodes = /* GraphQL */ `
  mutation ApproveOpNoteCptCodes($input: OpNoteCptCodesApproveRejectInput!) {
    approveOpNoteCptCodes(input: $input)
  }
`;
export const rejectOpNoteCptCodes = /* GraphQL */ `
  mutation RejectOpNoteCptCodes($input: OpNoteCptCodesApproveRejectInput!) {
    rejectOpNoteCptCodes(input: $input)
  }
`;
export const activateTenant = /* GraphQL */ `
  mutation ActivateTenant($tenantId: ID!) {
    activateTenant(tenantId: $tenantId)
  }
`;
export const deactivateTenant = /* GraphQL */ `
  mutation DeactivateTenant($tenantId: ID!) {
    deactivateTenant(tenantId: $tenantId)
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant($tenantId: ID!) {
    deleteTenant(tenantId: $tenantId)
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      name
      active
      createdBy
      updatedBy
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      name
      active
      createdBy
      updatedBy
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOpNote = /* GraphQL */ `
  mutation CreateOpNote(
    $input: CreateOpNoteInput!
    $condition: ModelOpNoteConditionInput
  ) {
    createOpNote(input: $input, condition: $condition) {
      id
      text
      type
      tenantId
      owner
      updatedBy
      createdAt
      updatedAt
      approvedCptCodes {
        items {
          id
          opNoteId
          cptCodeCode
          tenantId
          approvedBy
          approvedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOpNote = /* GraphQL */ `
  mutation UpdateOpNote(
    $input: UpdateOpNoteInput!
    $condition: ModelOpNoteConditionInput
  ) {
    updateOpNote(input: $input, condition: $condition) {
      id
      text
      type
      tenantId
      owner
      updatedBy
      createdAt
      updatedAt
      approvedCptCodes {
        items {
          id
          opNoteId
          cptCodeCode
          tenantId
          approvedBy
          approvedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOpNote = /* GraphQL */ `
  mutation DeleteOpNote(
    $input: DeleteOpNoteInput!
    $condition: ModelOpNoteConditionInput
  ) {
    deleteOpNote(input: $input, condition: $condition) {
      id
      text
      type
      tenantId
      owner
      updatedBy
      createdAt
      updatedAt
      approvedCptCodes {
        items {
          id
          opNoteId
          cptCodeCode
          tenantId
          approvedBy
          approvedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
