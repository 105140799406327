/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const mySecurityGroups = /* GraphQL */ `
  query MySecurityGroups {
    mySecurityGroups
  }
`;
export const myTenantId = /* GraphQL */ `
  query MyTenantId {
    myTenantId
  }
`;
export const listApprovedSuggestedOpNoteCptCodes = /* GraphQL */ `
  query ListApprovedSuggestedOpNoteCptCodes(
    $opNoteId: ID!
    $cptSuggestionMode: String
    $cptSuggestionSize: Int
  ) {
    listApprovedSuggestedOpNoteCptCodes(
      opNoteId: $opNoteId
      cptSuggestionMode: $cptSuggestionMode
      cptSuggestionSize: $cptSuggestionSize
    ) {
      cptCode {
        code
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      linkType
      approvedBy
      approvedAt
    }
  }
`;
export const listCptSuggestionModes = /* GraphQL */ `
  query ListCptSuggestionModes {
    listCptSuggestionModes
  }
`;
export const syncTenants = /* GraphQL */ `
  query SyncTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTenants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        active
        createdBy
        updatedBy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      active
      createdBy
      updatedBy
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        createdBy
        updatedBy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpNotes = /* GraphQL */ `
  query SyncOpNotes(
    $filter: ModelOpNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        type
        tenantId
        owner
        updatedBy
        createdAt
        updatedAt
        approvedCptCodes {
          items {
            id
            opNoteId
            cptCodeCode
            tenantId
            approvedBy
            approvedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOpNote = /* GraphQL */ `
  query GetOpNote($id: ID!) {
    getOpNote(id: $id) {
      id
      text
      type
      tenantId
      owner
      updatedBy
      createdAt
      updatedAt
      approvedCptCodes {
        items {
          id
          opNoteId
          cptCodeCode
          tenantId
          approvedBy
          approvedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const syncCptCodes = /* GraphQL */ `
  query SyncCptCodes(
    $filter: ModelCptCodeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCptCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        code
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCptCode = /* GraphQL */ `
  query GetCptCode($code: String!) {
    getCptCode(code: $code) {
      code
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCptCodes = /* GraphQL */ `
  query ListCptCodes(
    $code: String
    $filter: ModelCptCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCptCodes(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOpNoteCptCodeApproveds = /* GraphQL */ `
  query SyncOpNoteCptCodeApproveds(
    $filter: ModelOpNoteCptCodeApprovedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOpNoteCptCodeApproveds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        opNoteId
        cptCodeCode
        tenantId
        approvedBy
        approvedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchOpNotes = /* GraphQL */ `
  query SearchOpNotes(
    $filter: SearchableOpNoteFilterInput
    $sort: SearchableOpNoteSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOpNotes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        text
        type
        tenantId
        owner
        updatedBy
        createdAt
        updatedAt
        approvedCptCodes {
          items {
            id
            opNoteId
            cptCodeCode
            tenantId
            approvedBy
            approvedAt
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCptCodes = /* GraphQL */ `
  query SearchCptCodes(
    $filter: SearchableCptCodeFilterInput
    $sort: SearchableCptCodeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCptCodes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        code
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
