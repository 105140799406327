import { Grid, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FileHeader } from './FileHeader';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { myTenantId } from '../../../graphql/queries';

export interface SingleFileUploadWithProgressProps {
  file: File;
  onDelete: (file: File) => void;
  onUpload: (file: File, url: string) => void;
}

export function SingleFileUploadWithProgress({
                                               file,
                                               onDelete,
                                               onUpload,
                                             }: SingleFileUploadWithProgressProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function upload() {
      const url = await uploadFile(file, setProgress);
      onUpload(file, url);
    }

    upload();
  }, []);

  return (
    <Grid item>
      <FileHeader file={file} onDelete={onDelete} />
      <LinearProgress variant="determinate" value={progress} />
    </Grid>
  );
}

async function uploadFile(file: File, onProgress: (percentage: number) => void) {
  const endpoint = 'https://wfq2aoa7rb.execute-api.us-east-1.amazonaws.com/default/bill-e-note-sls-get-presigned-url-upload-op-note';
  const user = await Auth.currentAuthenticatedUser();
  // @ts-ignore
  const tenant: { data: { myTenantId: String }} = await API.graphql(graphqlOperation(myTenantId));
  const body = JSON.stringify({
    Key: `${process.env.REACT_APP_ENV}/${tenant.data.myTenantId}/${user.username}/${file.name}`,
    ContentType: file.type,
  });
  const requestOptions = {
    method: "POST",
    body
  };

  const response = await fetch(endpoint, requestOptions);
  let result = await response.json();
  const url = result.uploadURL;

  return new Promise<string>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / file.size) * 100;
        onProgress(Math.round(percentage));
      }
    };
    xhr.send(file);
  });
}