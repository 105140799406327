import React, { FC } from 'react';
import { BreadcrumbsItem } from '../../components/Breadcrumbs';
import { UserProfile } from '../UserProfile';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    title: 'My Profile',
  },
];

export const MyProfile: FC = () => {
  const userData = useSelector((state: RootState) => state.user.data);

  return (
    <UserProfile
      mode="view"
      breadcrumbs={breadcrumbs}
      id={userData?.Username}
    />
  );
};
