import { useCallback, useState } from 'react';

export default (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const open = useCallback(() => {
    setValue(true);
  }, [setValue]);
  const close = useCallback(() => {
    setValue(false);
  }, [setValue]);
  const toggle = useCallback(() => {
    setValue((current) => !current);
  }, [setValue]);

  return {
    value,
    open,
    close,
    toggle,
  };
};
