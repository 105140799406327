import { DateTime } from 'luxon';

const format = 'LL/dd/yyyy';

export default (date: number | string) => {
  if (typeof date === 'number') {
    return DateTime.fromMillis(date).toFormat(format);
  } else {
    return DateTime.fromISO(date).toFormat(format);
  }
};
