import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notifications } from './notifications';
import { reducer as opNotes } from './opNotes';
import { reducer as requests } from './requests';
import { reducer as cptCodes } from './cptCodes';
import { reducer as user } from './user';
import { reducer as users } from './users';
import { reducer as tenants } from './tenants';

const rootReducer = combineReducers({
  user,
  users,
  tenants,
  opNotes,
  requests,
  cptCodes,
  notifications,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
