import { Roles } from '../store/user';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { useMemo } from 'react';

export default (roles: Roles[]) => {
  const currentUserRole = useSelector((state: RootState) => state.user.role);

  return useMemo(() => roles.some((role) => currentUserRole.includes(role)), [
    roles,
    currentUserRole,
  ]);
};
