import React, { FC, useContext } from 'react';
import { AuthenticatorContext, Screens } from '../../Authenticator';

export type Props = {
  screen: Screens;
  text: string;
};

export const useScreenNav = () => {
  const { setScreen } = useContext(AuthenticatorContext);

  return setScreen;
};

export const ScreenNavLink: FC<Props> = (props) => {
  const { text, screen } = props;

  const { setScreen } = useContext(AuthenticatorContext);

  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        setScreen(screen);
      }}
    >
      {text}
    </a>
  );
};
