import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateOpNoteMutationVariables,
  UpdateOpNoteInput,
  SearchableOpNoteFilterInput,
} from '../../API';
import { CPTCode } from '../cptCodes';
import { Sort } from '../../components/Table';
import { actions as userActions } from '../user';

export type OpNote = {
  id: string;
  text: string;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  tenantId: string | null;
  _version: number;
  owner: string | null;
  approvedCptCodes: {
    items: CPTCode[] | null;
  };
};

export type OpNotes = {
  listOpNotes: {
    nextToken: string | null;
    items: OpNote[] | null;
  } | null;
};

export type OpNotesState = {
  data: OpNotes;
  selectedOpNote: OpNote | null;
  cptCodeDescription?: string;
};

export type LoadRequestParams = {
  filter: SearchableOpNoteFilterInput | null;
  sort?: Sort;
} | null;

const initialState: OpNotesState = {
  data: {
    listOpNotes: null,
  },
  selectedOpNote: null,
};

const slice = createSlice({
  name: 'opNotes',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<LoadRequestParams>) => state,
    loadMore: (state, action: PayloadAction<LoadRequestParams>) => state,
    loaded: (state, action: PayloadAction<OpNotes>) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    loadedMore: (state, action: PayloadAction<OpNotes>) => {
      if (state.data && state.data.listOpNotes) {
        return {
          ...state,
          data: {
            listOpNotes: {
              ...state.data.listOpNotes,
              ...action.payload.listOpNotes,
              items: [
                ...(state.data?.listOpNotes?.items ?? []),
                ...(action.payload?.listOpNotes?.items ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    create: (state, action: PayloadAction<CreateOpNoteMutationVariables>) =>
      state,
    loadOpNote: (state, action: PayloadAction<string>) => state,
    loadedOpNote: (state, action: PayloadAction<OpNote | null>) => {
      return {
        ...state,
        selectedOpNote: action.payload,
      };
    },
    update: (state, action: PayloadAction<UpdateOpNoteInput>) => state,
    updated: (
      state,
      action: PayloadAction<{ data: OpNote; updater: string }>
    ) => ({
      ...state,
      selectedOpNote: {
        ...state.selectedOpNote,
        ...action.payload.data,
      },
    }),
    delete: (state) => state,
    deleted: (state) => ({
      ...state,
      selectedOpNote: null,
    }),
    loadCptCodeDescription: (state, action: PayloadAction<string>) => ({
      ...state,
      cptCodeDescription: undefined,
    }),
    loadedCptCodeDescription: (state, action: PayloadAction<string>) => ({
      ...state,
      cptCodeDescription: action.payload,
    }),
  },
  extraReducers: {
    [userActions.signOut.type]: () => initialState,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
