import React, { PropsWithChildren } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export type Props<T> = {
  singleColumnFilter?: boolean;
  onSubmit: (values: FormikValues) => void;
  initialValues: T;
};

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > [class^="MuiGrid"]:first-child': {
      flexBasis: 'auto',
      flexShrink: 0,
    },
  },
});

export function Filters<T>(props: PropsWithChildren<Props<T>>) {
  const styles = useStyles();
  const { initialValues, onSubmit, children, singleColumnFilter } = props;

  return (
    <Formik
      initialStatus={{ singleColumnFilter }}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Grid className={styles.wrapper} item xs={12}>
        <Form className={styles.form}>{children}</Form>
      </Grid>
    </Formik>
  );
}
