import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actions as userActions } from '../user';

export type UserItem = {
  sub: string;
  userName: string;
  email: string;
  name: string;
  phone_number: string;
  role?: string[];
  tenant?: string;
  active?: boolean;
  status: string;
};

export type Users = {
  Users: UserItem[];
  PaginationToken?: string;
};

export type UsersState = {
  data: Users;
  filterQuery: string;
};

const initialState: UsersState = {
  data: {
    Users: [],
  },
  filterQuery: '',
};

const slice = createSlice({
  initialState,
  name: 'users',
  reducers: {
    load: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      filterQuery: action.payload || '',
    }),
    loaded: (state, action: PayloadAction<Users>) => ({
      ...state,
      data: action.payload,
    }),
    loadMore: (state, action: PayloadAction<string | undefined>) => state,
    loadedMore: (state, action: PayloadAction<Users>) => ({
      ...state,
      data: {
        ...state.data,
        ...action.payload,
        Users: [...state.data.Users, ...action.payload.Users],
      },
    }),
    deleteUser: (state, action: PayloadAction<string>) => state,
    reInviteUser: (state, action: PayloadAction<string>) => state,
  },
  extraReducers: {
    [userActions.signOut.type]: () => initialState,
  },
});

export const { actions, reducer } = slice;
