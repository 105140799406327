import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import { store } from './store';
import { Notifications } from './components/Notifications';
import history from './history';
import { Authenticator } from './screens/Authenticator';
Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Authenticator>
          <App />
        </Authenticator>
      </Router>
      <Notifications />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
