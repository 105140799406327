import { takeLatest, put, call, select } from 'redux-saga/effects';
import { actions, UserItem, Users } from './users.slice';
import requestSaga from '../requestSaga';
import { RootState } from '../rootReducer';

type UsersResponse = {
  Users: {
    Enabled: boolean;
    UserCreateDate: string;
    UserLastModifiedDate: string;
    UserStatus: string;
    Username: string;
    status: string;
    Groups: { GroupName: string }[];
    Attributes: { Name: string; Value: string }[];
  }[];
  PaginationToken?: string;
} | void;

const limit = 20;

function formatUsers(data: UsersResponse): Users {
  if (!data) {
    return {
      Users: [],
    };
  }

  return {
    PaginationToken: data.PaginationToken,
    Users: data.Users.map(({ Username, Attributes, UserStatus, Groups }) => {
      return {
        status: UserStatus,
        userName: Username,
        role: Groups.map((item) => item.GroupName),
        ...Attributes.reduce<Partial<UserItem>>((res, attr) => {
          const name = attr.Name.includes('custom')
            ? attr.Name.split(':')[1]
            : attr.Name;

          return {
            ...res,
            [name]: attr.Value,
          };
        }, {}),
      } as UserItem;
    }),
  };
}

export const loadUsersRequestName = 'loadUsers';
function* loadUsers(action: ReturnType<typeof actions.load>) {
  const response: UsersResponse = yield call(requestSaga, {
    name: loadUsersRequestName,
    rest: {
      apiName: 'AdminQueries',
      url: '/listUsers',
      method: 'get',
      data: {
        queryStringParameters: {
          limit,
          filter: action.payload,
        },
      },
    },
  });

  if (response && response.Users) {
    yield put(actions.loaded(formatUsers(response)));
  }
}

export const loadMoreUsersRequestName = 'loadMoreUsers';
function* loadMoreUsers(action: ReturnType<typeof actions.loadedMore>) {
  const token = yield select(
    (state: RootState) => state.users.data.PaginationToken
  );
  const response: UsersResponse = yield call(requestSaga, {
    name: loadMoreUsersRequestName,
    rest: {
      apiName: 'AdminQueries',
      url: '/listUsers',
      method: 'get',
      data: {
        queryStringParameters: {
          limit,
          token,
          filter: action.payload,
        },
      },
    },
  });

  if (response && response.Users) {
    yield put(actions.loadedMore(formatUsers(response)));
  }
}

const deleteUserAPIRequestName = 'deleteUserAPI';
const disableUserAPIRequestName = 'disableUserAPI';
export const deleteUserRequestNames = [
  disableUserAPIRequestName,
  deleteUserAPIRequestName,
];

function* deleteUserSaga(action: ReturnType<typeof actions.deleteUser>) {
  const disablingResponse = yield call(requestSaga, {
    rest: {
      apiName: 'AdminQueries',
      url: '/disableUser',
      method: 'post',
      data: {
        body: {
          username: action.payload,
        },
      },
    },
    name: disableUserAPIRequestName,
  });

  if (disablingResponse) {
    const response = yield call(requestSaga, {
      rest: {
        apiName: 'AdminQueries',
        url: '/deleteUser',
        method: 'post',
        data: {
          body: {
            username: action.payload,
          },
        },
      },
      name: deleteUserAPIRequestName,
    });

    if (response) {
      const filters = yield select(
        (state: RootState) => state.users.filterQuery
      );
      yield put(actions.load(filters));
    }
  }
}

export const reInviteUserRequestName = 'reInviteUser';

function* reInviteUserSaga(action: ReturnType<typeof actions.reInviteUser>) {
  yield call(requestSaga, {
    rest: {
      apiName: 'AdminQueries',
      url: '/reinviteUser',
      method: 'post',
      data: {
        body: {
          username: action.payload,
        },
      },
    },
    name: reInviteUserRequestName,
    successNotification: {
      message: 'Success reinviting NewUser',
    },
  });
}

export const saga = function* () {
  yield takeLatest(actions.load.type, loadUsers);
  yield takeLatest(actions.loadMore.type, loadMoreUsers);
  yield takeLatest(actions.deleteUser.type, deleteUserSaga);
  yield takeLatest(actions.reInviteUser.type, reInviteUserSaga);
};
