import React, { FC, useEffect, useMemo } from 'react';
import { Field, FieldProps, FieldAttributes, useFormikContext } from 'formik';
import { debounce } from 'lodash';

export type Props = FieldAttributes<any>;

export const FilterField: FC<Props> = (props) => {
  const { children, ...rest } = props;
  const { submitForm, status, initialValues } = useFormikContext<any>();
  const debouncedSubmit = useMemo(() => {
    return debounce(submitForm, 1000);
  }, [submitForm]);

  useEffect(() => {
    return debouncedSubmit.cancel;
  }, [debouncedSubmit]);

  return (
    <Field {...rest}>
      {(fieldProps: FieldProps) => {
        if (children && typeof children === 'function') {
          return children({
            ...fieldProps,
            field: {
              ...fieldProps.field,
              onChange: (e: any) => {
                if (status.singleColumnFilter) {
                  fieldProps.form.setValues({
                    ...initialValues,
                    [fieldProps.field.name]: e?.target?.value ?? e,
                  });
                } else {
                  fieldProps.form.setFieldValue(
                    fieldProps.field.name,
                    e?.target?.value ?? e
                  );
                }

                debouncedSubmit();
              },
            },
          });
        }

        return children;
      }}
    </Field>
  );
};
