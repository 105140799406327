import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  AppBar,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Drawer,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Route, Switch, NavLink, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/rootReducer';
import './App.css';
import { isProcessingSelector } from './store/requests';
import { actions, loadRoleRequestName } from './store/user';
import { Auth } from 'aws-amplify';
import { getHomeRoute, routes } from './routes';
import { makeStyles } from '@material-ui/core/styles';
import { NotFound } from './screens/NotFound';

import logo from './assets/logo.png';
import { Footer } from './layout/Footer';

const useStyles = makeStyles(({ palette: { primary, grey } }) => ({
  drawer: {
    width: 200,
  },
  logo: {
    display: 'inline-flex',
    alignItems: 'center',

    '& img': {
      marginRight: 8,
    },
  },
  navLink: {
    padding: 8,
    borderBottom: `1px solid ${grey.A200}`,
  },
  activeLink: {
    color: primary.main,
    textDecoration: 'none',
    backgroundColor: grey.A100,
  },
}));

const App = () => {
  const styles = useStyles();
  const { push } = useHistory();
  const user = useSelector((state: RootState) => state.user.data);
  const userRole = useSelector((state: RootState) => state.user.role);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navLinks = useMemo(() => {
    return routes.filter(
      (route) =>
        route.linkLabel &&
        (route?.roles?.some((role) => userRole.includes(role)) ?? true)
    );
  }, [userRole]);
  const isLoading = useSelector(isProcessingSelector([loadRoleRequestName]));

  const handleMenu = useCallback(
    (e: any) => {
      setAnchorEl(e.target);
    },
    [setAnchorEl]
  );
  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen((value) => !value);
  }, [setIsDrawerOpen]);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return isLoading || !user ? (
    <div className="app-spinner">
      <CircularProgress />
    </div>
  ) : (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          {navLinks.length > 1 && (
            <IconButton
              onClick={toggleDrawer}
              edge="start"
              className="menuButton"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Drawer
            classes={{
              paper: styles.drawer,
            }}
            open={isDrawerOpen}
            onClose={toggleDrawer}
          >
            {routes
              .filter(
                (route) =>
                  route.linkLabel &&
                  (route?.roles?.some((role) => userRole.includes(role)) ??
                    true)
              )
              .map((item, index) => (
                <NavLink
                  key={index}
                  to={item.path}
                  onClick={toggleDrawer}
                  className={styles.navLink}
                  activeClassName={styles.activeLink}
                >
                  {item.linkLabel}
                </NavLink>
              ))}
          </Drawer>
          <Typography variant="h6" color="inherit" className="title">
            <NavLink to={getHomeRoute().path} className={styles.logo}>
              <img src={logo} alt="Bill-e-Note" height={40} />
              Bill-e-Note
            </NavLink>
          </Typography>
          <div className="accountButton">
            <Typography className="username">{user?.Username}</Typography>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  push('/profile');
                }}
              >
                Profile
              </MenuItem>
              <Divider />
              <Button
                color="primary"
                variant="contained"
                style={{ margin: '8px 8px 0' }}
                onClick={async () => {
                  try {
                    await Auth.signOut();
                    dispatch(actions.signOut());
                  } catch (e) {}
                }}
              >
                Sign Out
              </Button>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Switch>
        <Redirect exact path="/" to={getHomeRoute().path} />
        {routes.reduce<ReactElement[]>((res, route, index) => {
          const content = <Route key={index} {...route} />;

          if (
            route?.roles?.length &&
            !userRole.some((role) => route?.roles?.includes(role))
          ) {
            return res;
          }

          return [...res, content];
        }, [])}
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
