import { call, put, select, takeLatest } from 'redux-saga/effects';
import requestSaga, { Request } from '../requestSaga';
import { createOpNote } from '../../graphql/mutations';
import { actions, OpNote } from '../opNotes';
import { RootState } from '../rootReducer';
import { User } from '../user';
import { forwardTo } from '../../history';

const limit = 20;

type OpNotesResponse = {
  data: {
    searchOpNotes: {
      items: {
        createdAt: string;
        id: string;
        owner: string;
        text: string;
        updatedAt: string;
        tenantId: string | null;
        updatedBy: string | null;
        _version: number;
        approvedCptCodes: {
          items: {
            approvedBy: string | null;
            cptCodeCode: string;
          }[];
        };
      }[];
      nextToken: string | null;
    };
  };
} | void;
const formatOpNote = (res: OpNotesResponse) => {
  if (!res) {
    return { listOpNotes: null };
  }

  return {
    listOpNotes: {
      ...res.data.searchOpNotes,
      items: res.data.searchOpNotes.items.map((item) => ({
        ...item,
        approvedCptCodes: {
          items: item.approvedCptCodes.items.map((code) => ({
            cptCode: {
              code: code.cptCodeCode,
              description: null,
            },
            approvedBy: code.approvedBy,
          })),
        },
      })),
    },
  };
};

const searchOpNotes = /* GraphQL */ `
  query SearchOpNotes(
    $filter: SearchableOpNoteFilterInput
    $sort: SearchableOpNoteSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOpNotes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        text
        type
        owner
        tenantId
        updatedBy
        createdAt
        updatedAt
        approvedCptCodes {
          items {
            id
            opNoteId
            cptCodeCode
            approvedBy
          }
          nextToken
          startedAt
        }
        _version
      }
      nextToken
      total
    }
  }
`;

export const loadOpNotesRequestName = 'loadOpNotes';
function* loadOpNotesSaga(action: ReturnType<typeof actions.load>) {
  const sortField = action.payload?.sort?.field ?? '';
  const res: OpNotesResponse = yield call(requestSaga, {
    operation: searchOpNotes,
    variables: {
      limit: limit,
      filter: action.payload?.filter,
      sort: {
        field: sortField,
        direction: action.payload?.sort?.dir,
      },
      nextToken: null,
    },
    name: loadOpNotesRequestName,
  });

  if (res) {
    yield put(actions.loaded(formatOpNote(res)));
  }
}

export const loadMoreOpNotesRequestName = 'loadMoreOpNotes';
function* loadMoreOpNotesSaga(action: ReturnType<typeof actions.loadMore>) {
  const sortField = action.payload?.sort?.field ?? '';
  const nextToken = yield select(
    (state: RootState) => state.opNotes.data.listOpNotes?.nextToken ?? null
  );
  const res: OpNotesResponse = yield call(requestSaga, {
    operation: searchOpNotes,
    variables: {
      limit,
      nextToken,
      filter: action.payload?.filter,
      sort: {
        field: sortField,
        direction: action.payload?.sort?.dir,
      },
    },
    name: loadMoreOpNotesRequestName,
  });

  if (res) {
    yield put(actions.loadedMore(formatOpNote(res)));
  }
}

export const createOpNoteRequestName = 'createOpNote';
function* createOpNoteSaga(action: ReturnType<typeof actions.create>) {
  const response: { data: { createOpNote: OpNote | null } } | null = yield call(
    requestSaga,
    {
      operation: createOpNote,
      variables: action.payload,
      name: createOpNoteRequestName,
      errorNotification: {
        message: 'Something went wrong. Please try again later.',
      },
    }
  );

  if (response && response.data.createOpNote) {
    yield forwardTo(`/opNotes/${response.data.createOpNote.id}`);
  }
}

export const loadOpNoteRequestName = 'loadOpNote';
function* loadOpNoteSaga(action: ReturnType<typeof actions.loadedOpNote>) {
  const response: Request<{ getOpNote: OpNote | null }> = yield call(
    requestSaga,
    {
      operation: /* GraphQL */ `
        query GetOpNote($id: ID!) {
          getOpNote(id: $id) {
            id
            text
            _version
            updatedBy
            createdAt
            updatedAt
            owner
          }
        }
      `,
      variables: { id: action.payload },
      name: loadOpNoteRequestName,
    }
  );

  if (response && response.data) {
    yield put(actions.loadedOpNote(response.data.getOpNote));
  }
}

export const updateOpNoteRequestName = 'updateOpNote';
function* updateOpNoteSaga(action: ReturnType<typeof actions.update>) {
  const user: User = yield select((state: RootState) => state.user.data);
  const response: { data: { updateOpNote: OpNote } } = yield call(requestSaga, {
    operation: /* GraphQL */ `
      mutation UpdateOpNote($input: UpdateOpNoteInput!) {
        updateOpNote(input: $input) {
          id
          text
          updatedBy
          updatedAt
          _version
        }
      }
    `,
    variables: { input: action.payload },
    name: updateOpNoteRequestName,
    successNotification: {
      message: 'OpNote has been updated',
    },
  });

  yield put(
    actions.updated({
      data: response.data.updateOpNote,
      updater: user?.Username ?? '',
    })
  );
}

export const deleteOpNoteRequestName = 'deleteOpNote';
function* deleteOpNoteSaga() {
  const opNote = yield select(
    (state: RootState) => state.opNotes.selectedOpNote
  );
  if (opNote) {
    const response = yield call(requestSaga, {
      operation: /* GraphQL */ `
        mutation DeleteOpNote($input: DeleteOpNoteInput!) {
          deleteOpNote(input: $input) {
            id
          }
        }
      `,
      variables: { input: { id: opNote.id, _version: opNote._version } },
      name: deleteOpNoteRequestName,
      successNotification: {
        message: 'OpNote has been deleted',
      },
    });

    if (response) {
      yield put(actions.deleted());

      yield forwardTo('/');
    }
  }
}

export const saga = function* opNotesSaga() {
  yield takeLatest(actions.load.type, loadOpNotesSaga);
  yield takeLatest(actions.loadMore.type, loadMoreOpNotesSaga);
  yield takeLatest(actions.create.type, createOpNoteSaga);
  yield takeLatest(actions.loadOpNote.type, loadOpNoteSaga);
  yield takeLatest(actions.update.type, updateOpNoteSaga);
  yield takeLatest(actions.delete.type, deleteOpNoteSaga);
};
