import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelTenantFilterInput, UpdateTenantInput } from '../../API';
import { User } from '../user';

export type LoadRequestParams = {
  filter: ModelTenantFilterInput | null;
} | null;

export type TenantItem = {
  id: string;
  name: string;
  createdBy: string;
  updatedBy: string;
  active: boolean;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  createdAt: string;
  updatedAt: string;
};

export type Tenants = {
  listTenants: {
    items: TenantItem[];
    nextToken?: string | null;
  };
};

export type TenantsState = {
  data: Tenants;
  requestParams: LoadRequestParams;
  userProfileTenants: TenantItem[];
  selectedTenant: TenantItem | null;
};

const initialState: TenantsState = {
  data: {
    listTenants: {
      items: [],
    },
  },
  requestParams: null,
  selectedTenant: null,
  userProfileTenants: [],
};

const slice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<LoadRequestParams | undefined>) => ({
      ...state,
      requestParams: { filter: action.payload?.filter ?? null },
    }),
    loaded(state, action: PayloadAction<Tenants>) {
      return {
        ...state,
        data: action.payload,
      };
    },
    loadMore: (state, action: PayloadAction<LoadRequestParams>) => state,
    loadedMore(state, action: PayloadAction<Tenants>) {
      if (state.data && state.data.listTenants) {
        return {
          ...state,
          data: {
            listTenants: {
              ...state.data.listTenants,
              ...action.payload.listTenants,
              items: [
                ...(state.data?.listTenants?.items ?? []),
                ...(action.payload?.listTenants?.items ?? []),
              ],
            },
          },
        };
      }

      return state;
    },
    loadTenant: (state, action: PayloadAction<string>) => state,
    loadedTenant: (state, action: PayloadAction<TenantItem>) => ({
      ...state,
      selectedTenant: action.payload,
    }),
    create: (state, action: PayloadAction<User>) => state,
    update: (state, action: PayloadAction<UpdateTenantInput>) => state,
    loadUserTenants: (state, action: PayloadAction<boolean | undefined>) =>
      state,
    loadedUserTenants: (state, action: PayloadAction<TenantItem[]>) => ({
      ...state,
      userProfileTenants: action.payload,
    }),
    toggle: (state, action: PayloadAction<{ id: string; active: boolean }>) =>
      state,
    toggled: (
      state,
      {
        payload: { id, active },
      }: PayloadAction<{ id: string; active: boolean }>
    ) => ({
      ...state,
      data: {
        ...state.data,
        listTenants: {
          ...state.data.listTenants,
          items: state.data.listTenants.items.map((item) => {
            return item.id === id ? { ...item, active } : item;
          }),
        },
      },
    }),
    delete: (state, action: PayloadAction<{ id: string; active: boolean }>) =>
      state,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
