import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { FilterField } from '../FilterField';

export type Values = (string | null)[];

export type Props = {
  name: string;
};

export const DateFilter: FC<Props> = (props) => {
  const { name } = props;

  return (
    <FilterField name={name}>
      {({ field: { value = [null, null], onChange } }: FieldProps<Values>) => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  autoFocus
                  label="From"
                  value={value[0]}
                  maxDate={value[1]}
                  onChange={(newValue) => {
                    onChange([newValue?.toISOString(), value[1]]);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  label="To"
                  minDate={value[0]}
                  value={value[1]}
                  onChange={(newValue) => {
                    onChange([value[0], newValue?.toISOString()]);
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        );
      }}
    </FilterField>
  );
};
