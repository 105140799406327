import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { isProcessingSelector } from '../../../../store/requests';
import {
  toggleTenantRequestName,
  actions,
  deleteTenantRequestName,
} from '../../../../store/tenants';

export type Props = {
  id: string;
  active: boolean;
};

export const ToggleTenant: FC<Props> = (props) => {
  const { id, active } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector(
    isProcessingSelector([toggleTenantRequestName, deleteTenantRequestName])
  );

  const handleToggle = useCallback(() => {
    dispatch(
      actions.toggle({
        id,
        active,
      })
    );
  }, [active, id, dispatch]);

  return (
    <div>
      <Button
        variant="contained"
        disabled={isLoading}
        onClick={handleToggle}
        color={active ? 'secondary' : 'primary'}
      >
        {active ? 'Deactivate' : 'Activate'}
      </Button>
    </div>
  );
};
