import React, { useCallback, useEffect, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Page } from '../../layout/Page';
import { TableFilters } from '../../components/Table';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import {
  actions,
  deleteUserRequestNames,
  loadMoreUsersRequestName,
  loadUsersRequestName,
  reInviteUserRequestName,
  UserItem,
} from '../../store/users';
import { actions as tenantsActions } from '../../store/tenants';
import { isProcessingSelector } from '../../store/requests';
import { FormikValues } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Roles } from '../../store/user';
import { loadUserProfileTenantsRequestName } from '../../store/tenants';

const getFilter = (values: FormikValues) => {
  const fieldName = Object.keys(values).find((key) => !!values[key]);
  const value = fieldName ? values[fieldName] : '';

  return value ? `${fieldName}^=\"${value}\"` : '';
};

const useStyles = makeStyles({
  buttonGroup: {
    textAlign: 'right',

    '& button': {
      margin: 4,
    },
  },
});

export const Users = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const currentUserRole = useSelector((state: RootState) => state.user.role);
  const isLoading = useSelector(
    isProcessingSelector([
      loadUsersRequestName,
      loadUserProfileTenantsRequestName,
    ])
  );
  const isLoadingMore = useSelector(
    isProcessingSelector([loadMoreUsersRequestName])
  );
  const isReInviting = useSelector(
    isProcessingSelector([reInviteUserRequestName])
  );
  const isDeleting = useSelector(isProcessingSelector(deleteUserRequestNames));
  const storeItems = useSelector((state: RootState) => state.users.data.Users);
  const tenants = useSelector(
    (state: RootState) => state.tenants.userProfileTenants
  );
  const hasMore = useSelector(
    (state: RootState) => !!state.users.data.PaginationToken
  );
  const isSuperAdmin = currentUserRole.includes(Roles.SuperAdmin);
  const items = useMemo(() => {
    if (isSuperAdmin) {
      const tenantsMap = new Map(tenants.map((item) => [item.id, item]));

      return storeItems.map((item) => ({
        ...item,
        active: item.tenant ? tenantsMap.get(item.tenant)?.active : true,
        tenant: item.tenant ? tenantsMap.get(item.tenant)?.name : item.tenant,
      }));
    }

    return storeItems;
  }, [storeItems, tenants, isSuperAdmin]);

  const handleAdd = useCallback(() => {
    push('/users/new');
  }, [push]);

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(tenantsActions.loadUserTenants());
    }
  }, [dispatch, isSuperAdmin]);

  return (
    <Page grid>
      <Grid className="heightWrapper" item xs={12}>
        <TableFilters
          singleColumnFilter
          hasMore={hasMore}
          loadingMore={isLoadingMore}
          columns={[
            {
              name: 'sub',
              label: 'ID',
              filter: {
                name: 'sub',
              },
              render: (data: UserItem) => (
                <NavLink title={data.sub} to={`/users/${data.userName}`}>
                  {data.sub.split('-')[0]}
                </NavLink>
              ),
            },
            {
              name: 'userName',
              label: 'Name',
              filter: {
                name: 'username',
              },
            },
            ...(isSuperAdmin
              ? [
                  {
                    name: 'tenant',
                    label: 'Tenant',
                  },
                ]
              : []),
            {
              name: 'email',
              label: 'Email',
              filter: {
                name: 'email',
              },
            },
            {
              name: 'phone_number',
              label: 'Phone',
              filter: {
                name: 'phone_number',
              },
            },
            {
              name: 'role',
              label: 'Role',
              format: (value: string[]) => value.join(', ') ?? '',
            },
            { name: 'specialty', label: 'Specialty' },
            { name: 'practice', label: 'Practice' },
            {
              name: 'username',
              render: (data: UserItem) => {
                return (
                  <div className={styles.buttonGroup}>
                    {data.active && data.status === 'FORCE_CHANGE_PASSWORD' && (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isReInviting}
                        onClick={() =>
                          dispatch(actions.reInviteUser(data.userName))
                        }
                      >
                        ReInvite
                      </Button>
                    )}
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={isDeleting}
                      onClick={() =>
                        dispatch(actions.deleteUser(data.userName))
                      }
                    >
                      Delete
                    </Button>
                  </div>
                );
              },
            },
          ]}
          columnsData={items}
          onSubmit={useCallback(
            (values) => {
              dispatch(actions.load(getFilter(values)));
            },
            [dispatch]
          )}
          onSubmitMore={useCallback(
            (values) => {
              dispatch(actions.loadMore(getFilter(values)));
            },
            [dispatch]
          )}
          initialValues={{}}
          loading={isLoading}
          actions={
            <Button color="primary" variant="contained" onClick={handleAdd}>
              Add User
            </Button>
          }
        />
      </Grid>
    </Page>
  );
};
