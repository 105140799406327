import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actions as userActions } from '../user';

export type RequestsState = {
  processing: {
    [key: string]: boolean;
  };
  errors: {
    [key: string]: string | null;
  };
};

const initialState: RequestsState = {
  processing: {},
  errors: {},
};

const slice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    start: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        processing: {
          ...state.processing,
          [action.payload]: true,
        },
        errors: {
          [action.payload]: null,
        },
      };
    },
    finish: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        processing: {
          ...state.processing,
          [action.payload]: false,
        },
      };
    },
    error: (state, action: PayloadAction<{ name: string; error: string }>) => {
      const { name, error } = action.payload;

      return {
        ...state,
        processing: {
          ...state.processing,
          [name]: false,
        },
        errors: {
          ...state.errors,
          [name]: error,
        },
      };
    },
  },
  extraReducers: {
    [userActions.signOut.type]: () => initialState,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
