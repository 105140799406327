import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';
import { FilterField } from '../FilterField';

export type Props = {
  name: string;
} & TextFieldProps;

export const TextFilter: FC<Props> = (props) => {
  const { name, ...rest } = props;

  return (
    <FilterField name={name}>
      {({ field }: FieldProps) => {
        return (
          <TextField autoFocus {...field} value={field.value || ''} {...rest} />
        );
      }}
    </FilterField>
  );
};
