import React, { Component } from 'react';
import { Button, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom'

const breadcrumbs = [
  {
    title: '/ Feedback',
  },
];

export class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      text: ""
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


  onAddClick = async () => {
    const endpoint = "https://wwt8mxutn4.execute-api.us-east-1.amazonaws.com/default/bill-e-note-sls-dev-feedback-send-email";
    const user = await Auth.currentAuthenticatedUser();
    let body;
    try{
      body = JSON.stringify({
        username: user.username,
        name: user.attributes.name,
        email: user.attributes.email,
        phone_number: user.attributes.phone_number,
        opnote_id: this.props.location.state.opnote_id,
        subject: this.state.subject,
        text: this.state.text
      });
    }
    catch (e) {
      body = JSON.stringify({
        username: user.username,
        name: user.attributes.name,
        email: user.attributes.email,
        phone_number: user.attributes.phone_number,
        opnote_id: "undefined",
        subject: this.state.subject,
        text: this.state.text
      });
    }
    const requestOptions = {
      method: "POST",
      body
    };

    fetch(endpoint, requestOptions)
      .then((response) => {
        if (!response.ok) throw new Error("Error in fetch");
        return response.json();
      });

    this.props.history.push('/feedback/confirm');
  };

  render() {
    return (
      <Page>
        <Grid item xs={12}>
          <form onSubmit={this.onAddClick}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Breadcrumbs items={breadcrumbs} />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Choose the feedback subject:</InputLabel>
                <Select
                  fullWidth
                  multiline
                  name="subject"
                  value={this.subject}
                  onChange={this.onChange}
                >
                  <MenuItem value={"Technical Issue"}>Technical Issue</MenuItem>
                  <MenuItem value={"User Feedback"}>User Feedback</MenuItem>
                  <MenuItem value={"Code Denial"}>Code Denial</MenuItem>
                  <MenuItem value={"Suggest a Feature"}>Suggest a Feature</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Please write the text of the feedback:</FormLabel>
                <TextField
                  fullWidth
                  multiline
                  name="text"
                  value={this.text}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12}>
                <Button
                  disabled={!this.state.text && !this.state.subject}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Page>
    );
  }
}