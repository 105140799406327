import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ListApprovedSuggestedOpNoteCptCodesQueryVariables,
  OpNoteCptCodeLinkType,
  OpNoteCptCodesApproveRejectInput,
} from '../../API';
import { actions as userActions } from '../user';

export type CPTCode = {
  cptCode: {
    code: string;
    description: string | null;
  };
  linkType?: OpNoteCptCodeLinkType;
  approvedBy?: string | null;
  approvedAt?: string | null;
};

export type CPTCodesState = {
  items: CPTCode[];
  search: string;
  suggestionModes: string[];
  opNoteApprovedSuggestedCodes: CPTCode[];
};

const initialState: CPTCodesState = {
  items: [],
  search: '',
  suggestionModes: [],
  opNoteApprovedSuggestedCodes: [],
};

const slice = createSlice({
  initialState,
  name: 'CPTCodes',
  reducers: {
    loaded: (state, action: PayloadAction<CPTCode[]>) => ({
      ...state,
      items: action.payload,
    }),
    search: (state, action: PayloadAction<string>) => ({
      ...state,
      items: [],
      search: action.payload,
    }),
    loadOpNoteApprovedSuggestedCodes: (
      state,
      action: PayloadAction<ListApprovedSuggestedOpNoteCptCodesQueryVariables>
    ) => ({
      ...state,
      opNoteApprovedSuggestedCodes: [],
    }),
    loadedOpNoteApprovedSuggestedCodes: (
      state,
      action: PayloadAction<CPTCode[]>
    ) => ({
      ...state,
      opNoteApprovedSuggestedCodes: action.payload,
    }),
    approve: (state, action: PayloadAction<OpNoteCptCodesApproveRejectInput>) =>
      state,
    submit: (state, action: PayloadAction<OpNoteCptCodesApproveRejectInput>) =>
      state,
    approved: (
      state,
      action: PayloadAction<{ codeIds: string[]; approver: string }>
    ) => ({
      ...state,
      opNoteApprovedSuggestedCodes: state.opNoteApprovedSuggestedCodes.map(
        (item) => {
          const approvedItem = action.payload.codeIds.find(
            (approvedId) => approvedId === item.cptCode.code
          );
          if (approvedItem) {
            return {
              ...item,
              approvedBy: action.payload.approver,
              linkType: OpNoteCptCodeLinkType.APPROVED,
            };
          }
          return item;
        }
      ),
    }),
    reject: (state, action: PayloadAction<OpNoteCptCodesApproveRejectInput>) =>
      state,
    rejected: (state, action: PayloadAction<string[]>) => ({
      ...state,
      opNoteApprovedSuggestedCodes: state.opNoteApprovedSuggestedCodes.filter(
        (item) => {
          const rejectedItem = action.payload.find(
            (rejectedId) => rejectedId === item.cptCode.code
          );

          return !rejectedItem;
        }
      ),
    }),
    assign: (
      state,
      action: PayloadAction<{ opNoteId: string; cptCodes: CPTCode[] }>
    ) => state,
    assigned: (
      state,
      action: PayloadAction<{ codes: CPTCode[]; approver: string }>
    ) => {
      const newCodes = new Set(
        action.payload.codes.map((item) => item.cptCode.code)
      );

      return {
        ...state,
        opNoteApprovedSuggestedCodes: [
          ...action.payload.codes.map((item) => ({
            ...item,
            approvedBy: action.payload.approver,
            linkType: OpNoteCptCodeLinkType.APPROVED,
          })),
          ...state.opNoteApprovedSuggestedCodes.filter(
            (item) => !newCodes.has(item.cptCode.code)
          ),
        ],
      };
    },
    loadSuggestionModes: (state) => state,
    loadedSuggestionModes: (state, action: PayloadAction<string[]>) => ({
      ...state,
      suggestionModes: action.payload,
    }),
  },
  extraReducers: {
    [userActions.signOut.type]: () => initialState,
  },
});

export const { reducer, actions } = slice;
