import { Button, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { MultipleFileUploadField } from './upload';
import { Page } from '../../layout/Page';
import {Breadcrumbs, BreadcrumbsItem} from "../../components/Breadcrumbs";
import history from '../../history';
import { useHistory } from 'react-router-dom';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: '/opNotes',
    title: 'OpNotes',
  },
  {
    title: 'Upload new OpNote',
  },
];

export const UploadOnNote: FC = () => {
  const { push } = useHistory();
  return (
    <Page>
        <Grid item xs={12}>
          <Formik
            initialValues={{ files: [] }}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              setTimeout(() => {
                history.push('/opNotes/');
              }, 5000);
            }}
          >
            {({ dirty, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid container item xs={12} justify="space-between">
                        <Breadcrumbs items={breadcrumbs} />
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => push('/OpNotes/new')}
                          style={{ marginRight: 16 }}
                        >
                          Add OpNote
                        </Button>
                        <Button
                          variant="contained"
                          color="default"
                          disabled={!dirty || isSubmitting}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                     <Grid item xs={12}>
                        <MultipleFileUploadField name="files" isSubmitting={isSubmitting} />
                     </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
    </Page>
  )
};