import React, { FC } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: ({ withoutScroll }: { withoutScroll?: boolean }) => ({
    padding: '16px 16px 0',
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
    alignItems: withoutScroll ? 'stretch' : 'none',
    flexDirection: 'column',

    '& .heightWrapper': {
      flexGrow: withoutScroll ? 1 : 0,
      overflow: withoutScroll ? 'hidden' : 'auto',
      alignItems: withoutScroll ? 'stretch' : 'none',
    },
  }),
});

export type Props = {
  grid?: boolean;
  className?: string;
  withoutScroll?: boolean;
};

export const Page: FC<Props> = (props) => {
  const { grid, withoutScroll } = props;
  const styles = useStyles({ withoutScroll });

  return (
    <div className={`${styles.root} ${grid ? 'GridPage' : ''}`}>
      <Grid className="heightWrapper" container spacing={2}>
        {props.children}
      </Grid>
    </div>
  );
};
