import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '../../layout/Page';
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  actions,
  deleteOpNoteRequestName,
  loadOpNoteRequestName,
  OpNote,
  updateOpNoteRequestName,
} from '../../store/opNotes';
import { Breadcrumbs, BreadcrumbsItem } from '../../components/Breadcrumbs';
import { RootState } from '../../store/rootReducer';
import { isProcessingSelector } from '../../store/requests';
import { OpNoteDetailsDialog } from './components/OpNoteDetailsDialog';
import { OpNoteCPTCodes } from './components/OpNoteCPTCodes';
import usePrevious from '../../hooks/usePrevious';
import {
  actions as CPTCodesActions,
  loadApprovedSuggestedOpNoteCptCodesRequestName,
} from '../../store/cptCodes';
import { OpNoteCptCodeLinkType } from '../../API';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { usePermission } from '../../components/Permission';
import { Roles } from '../../store/user';
import { NotFound } from '../NotFound';
import { Expandable } from '../../components/Expandable';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SuggestionModes } from './components/SuggestionModes';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    href: '/opNotes',
    title: 'OpNotes',
  },
  {
    title: 'View OpNote',
  },
];

const useStyles = makeStyles({
  form: {
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      padding: 8,
      flexBasis: 'auto',
    },
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  }),
);

export const ViewOpNote: FC = () => {
  const styles = useStyles();
  const classes = useStyles2();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [suggestedCPTCodesListSize, setSuggestedCPTCodesListSize] = useState(
    50
  );
  const [suggestionMode, setSuggestionMode] = useState('');
  const [editingText, setEditingText] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [details, setDetails] = useState<OpNote | null>(null);
  const isLoadingApprovedSuggestedCodes = useSelector(
    isProcessingSelector([loadApprovedSuggestedOpNoteCptCodesRequestName])
  );
  const isLoading = useSelector(isProcessingSelector([loadOpNoteRequestName]));
  const isUpdating = useSelector(
    isProcessingSelector([updateOpNoteRequestName])
  );
  const isDeleting = useSelector(
    isProcessingSelector([deleteOpNoteRequestName])
  );
  const approvedSuggestedCodes = useSelector(
    (state: RootState) => state.cptCodes.opNoteApprovedSuggestedCodes
  );
  const data = useSelector((state: RootState) => state.opNotes.selectedOpNote);
  const previousData = usePrevious(data);
  const isDataEntry = usePermission([Roles.DataEntry]);
  const waitingForSuggestionMode = !isDataEntry && !suggestionMode;
  const canBeEdited = useMemo(() => {
    return (
      !waitingForSuggestionMode &&
      !isLoadingApprovedSuggestedCodes &&
      (!isDataEntry ||
        (isDataEntry &&
          (approvedSuggestedCodes.length === 0 ||
            approvedSuggestedCodes.every(
              (item) => item.linkType === OpNoteCptCodeLinkType.SUGGESTED
            ))))
    );
  }, [
    isDataEntry,
    approvedSuggestedCodes,
    waitingForSuggestionMode,
    isLoadingApprovedSuggestedCodes,
  ]);

  const handleOpenDetails = useCallback(() => {
    setDetails(data);
  }, [setDetails, data]);
  const handleCloseDetails = useCallback(() => {
    setDetails(null);
  }, [setDetails]);
  const handleEditBtnClick = useCallback(() => {
    setIsEditable((value) => {
      if (value) {
        setShowEditConfirmation(true);
        return value;
      }
      setEditingText(data?.text ?? '');
      return true;
    });
  }, [setIsEditable, setEditingText, data]);
  const handleChangeText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEditingText(e.target.value);
    },
    [setEditingText]
  );
  const handleDialogClose = useCallback(() => setShowConfirmation(false), [
    setShowConfirmation,
  ]);
  const handleDialogSubmit = useCallback(() => {
    dispatch(actions.delete());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.loadOpNote(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      data &&
      previousData &&
      data !== previousData &&
      editingText &&
      isEditable
    ) {
      setEditingText('');
      setIsEditable(false);
    }
  }, [
    data,
    previousData,
    editingText,
    isEditable,
    setEditingText,
    setIsEditable,
  ]);
  useEffect(() => {
    if (data && !waitingForSuggestionMode) {
      dispatch(
        CPTCodesActions.loadOpNoteApprovedSuggestedCodes({
          opNoteId: data.id,
          cptSuggestionMode: suggestionMode || null,
          cptSuggestionSize: suggestedCPTCodesListSize,
        })
      );
    }
  }, [
    dispatch,
    data,
    suggestionMode,
    waitingForSuggestionMode,
    suggestedCPTCodesListSize,
  ]);

  if (!isLoading && !data) {
    return <NotFound />;
  }

  return (
    <Page withoutScroll>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          if (data) {
            dispatch(
              actions.update({
                id: data.id,
                text: editingText,
                _version: data._version,
              })
            );
          }
        }}
      >
        {isLoading ? (
          <Grid container item xs={12} justify="center">
            <CircularProgress />
          </Grid>
        ) : data ? (
          <>
            <Grid container item xs={12} justify="space-between">
              <Breadcrumbs items={breadcrumbs} />
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => push('/OpNotes/new')}
                  className={classes.button}
                >
                  Add OpNote
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => push('/OpNotes/upload')}
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>OpNote:</FormLabel>
              {isEditable ? (
                <TextField
                  fullWidth
                  multiline
                  value={editingText}
                  onChange={handleChangeText}
                />
              ) : (
                <Expandable>
                  <Typography className={styles.text} component="pre">
                    {data.text}
                  </Typography>
                </Expandable>
              )}
            </Grid>
            <Grid container item xs={12} alignItems="center" wrap="nowrap">
              {!isDataEntry && !isEditable && (
                <>
                  <FormControl
                    fullWidth
                    style={{ maxWidth: 160, marginRight: 16 }}
                  >
                    <FormLabel>Max Suggestions:</FormLabel>
                    <Select
                      value={suggestedCPTCodesListSize}
                      onChange={(e) => {
                        setSuggestedCPTCodesListSize(Number(e.target.value));
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                  <SuggestionModes onSelect={setSuggestionMode} />
                </>
              )}
              <div style={{ marginLeft: 'auto', flexShrink: 0 }}>
                {!isEditable && (
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 16 }}
                    onClick={() => push(`/feedback`, {opnote_id: data.id})}
                  >
                    Feedback
                  </Button>
                )}
                {!isEditable && (
                  <Button
                    color="default"
                    variant="contained"
                    style={{ marginRight: 16 }}
                    onClick={handleOpenDetails}
                  >
                    Details
                  </Button>
                )}
                {isEditable && (
                  <Button
                    disabled={isUpdating}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
                {canBeEdited && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleEditBtnClick}
                  >
                    {isEditable ? 'Cancel' : 'Edit'}
                  </Button>
                )}
              </div>
            </Grid>
            {!isEditable && (
              <>
                {isLoadingApprovedSuggestedCodes || waitingForSuggestionMode ? (
                  <Grid container justify="center">
                    <CircularProgress />
                  </Grid>
                ) : (
                  <OpNoteCPTCodes
                    opNoteId={data?.id ?? ''}
                    codes={approvedSuggestedCodes || []}
                  />
                )}
              </>
            )}
          </>
        ) : null}
        {details && (
          <OpNoteDetailsDialog
            data={details}
            open={!!details}
            deletable={canBeEdited}
            onDelete={() => {
              setDetails(null);
              setShowConfirmation(true);
            }}
            codes={approvedSuggestedCodes}
            onClose={handleCloseDetails}
          />
        )}
        <ConfirmationDialog
          disabled={isDeleting}
          text="You are going to delete the OpNote"
          open={showConfirmation}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmit}
        />
        <ConfirmationDialog
          text="The changes you made will be lost. Are you sure you want to leave editing mode?"
          open={showEditConfirmation}
          onClose={() => {
            setShowEditConfirmation(false);
          }}
          onSubmit={() => {
            setIsEditable(false);
            setShowEditConfirmation(false);
          }}
        />
      </form>
    </Page>
  );
};
