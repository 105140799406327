import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { actions, User } from './user.slice';
import requestSaga, { Request } from '../requestSaga';
import { forwardTo } from '../../history';
import { RootState } from '../rootReducer';
import { isAdmin, Roles } from './roles';

const formatUserAttributes = (attributes: {
  [key: string]: string;
}): { Name: string; Value: string }[] => {
  return Object.entries(attributes).reduce<{ Name: string; Value: string }[]>(
    (res, item) => {
      return [
        ...res,
        {
          Name: item[0],
          Value: item[1],
        },
      ];
    },
    []
  );
};

export const loadRoleRequestName = 'loadRole';
function* loadRoleSaga() {
  const response: Request<{ mySecurityGroups: Roles[] }> = yield call(
    requestSaga,
    {
      operation: /* GraphQL */ `
        query MySecurityGroups {
          mySecurityGroups
        }
      `,
      name: loadRoleRequestName,
    }
  );

  if (response && response.data) {
    yield put(actions.loadedRole(response?.data?.mySecurityGroups ?? []));
  }
}

export const createUserRequestName = 'createUser';
function* createUserSaga(action: ReturnType<typeof actions.create>) {
  const { tmppassword, ...attributes } = action.payload.UserAttributes;

  const response: Request<{ mySecurityGroups: Roles[] }> = yield call(
    requestSaga,
    {
      rest: {
        apiName: 'AdminQueries',
        url: '/addUser',
        method: 'post',
        data: {
          body: {
            user: {
              Username: action.payload.Username,
              Attributes: [
                ...formatUserAttributes(attributes),
                {
                  Name: 'phone_number_verified',
                  Value: 'true',
                },
                {
                  Name: 'email_verified',
                  Value: 'true',
                },
              ],
              Groups: [
                {
                  GroupName: action.payload.Groups,
                },
              ],
            },
            tmppassword,
          },
        },
      },
      successNotification: action.payload.isTenantAdmin
        ? undefined
        : {
            message: 'User has been created',
          },
      name: createUserRequestName,
    }
  );

  if (response) {
    if (action.payload.isTenantAdmin) {
      yield put(actions.created());
    } else {
      yield forwardTo('/users');
    }
  }
}

export const loadUserProfileRequestName = 'loadUserProfile';
export const loadUserRoleRequestName = 'loadUserRole';
function* loadUserProfileSaga(action: ReturnType<typeof actions.load>) {
  const currentUserRole = yield select((state: RootState) => state.user.role);
  const isCurrentUserAdmin = isAdmin(currentUserRole);

  const responses: any[] = yield all([
    call(requestSaga, {
      rest: {
        apiName: 'AdminQueries',
        url: isCurrentUserAdmin ? '/getUser' : '/getProfile',
        method: 'get',
        data: {
          queryStringParameters: {
            username: action.payload,
          },
        },
      },
      name: loadUserProfileRequestName,
    }),
    ...(isCurrentUserAdmin
      ? [
          call(requestSaga, {
            rest: {
              apiName: 'AdminQueries',
              url: '/listGroupsForUser',
              method: 'get',
              data: {
                queryStringParameters: {
                  username: action.payload,
                },
              },
            },
            name: loadUserRoleRequestName,
          }),
        ]
      : []),
  ]);

  if (responses.every(Boolean)) {
    yield put(
      actions.loadedProfile({
        UserAttributes: responses[0].UserAttributes.reduce(
          (res: any, item: any) => {
            return {
              ...res,
              [item.Name]: item.Value,
            };
          },
          {}
        ),
        Groups: responses[1]?.Groups[0]?.GroupName ?? '',
        Username: responses[0].Username,
      })
    );
  }
}

export const updateUserProfileRequestName = 'updateUserProfile';
export const removeUserFromGroupRequestName = 'removeUserFromGroup';
export const addUserToGroupRequestName = 'addUserToGroup';
export const updateUserRequestName = [
  updateUserProfileRequestName,
  removeUserFromGroupRequestName,
  addUserToGroupRequestName,
];
function* updateUserProfileSaga(
  action: ReturnType<typeof actions.updateProfile>
) {
  const profile: User = yield select((state: RootState) => state.user.profile);

  if (profile) {
    if (profile.Groups) {
      yield call(requestSaga, {
        name: removeUserFromGroupRequestName,
        rest: {
          apiName: 'AdminQueries',
          url: '/removeUserFromGroup',
          method: 'post',
          data: {
            body: {
              username: action.payload.Username,
              groupname: profile.Groups,
            },
          },
        },
      });
    }

    yield call(requestSaga, {
      name: addUserToGroupRequestName,
      rest: {
        apiName: 'AdminQueries',
        url: '/addUserToGroup',
        method: 'post',
        data: {
          body: {
            username: action.payload.Username,
            groupname: action.payload.Groups,
          },
        },
      },
    });
  }

  const { sub, ...attributes } = action.payload.UserAttributes;
  const response = yield call(requestSaga, {
    rest: {
      apiName: 'AdminQueries',
      url: '/updateUser',
      method: 'post',
      data: {
        body: {
          user: {
            Username: action.payload.Username,
            Attributes: [
              ...formatUserAttributes(attributes),
              {
                Name: 'phone_number_verified',
                Value: 'true',
              },
              {
                Name: 'email_verified',
                Value: 'true',
              },
            ],
            Groups: [action.payload.Groups],
          },
        },
      },
    },
    successNotification: {
      message: 'Profile has been updated',
    },
    name: updateUserProfileRequestName,
  });

  if (response) {
    yield put(actions.updatedProfile(action.payload));
  }
}

export const loadTenantIdRequestName = 'loadTenantId';
function* loadTenantIdSaga() {
  const response: Request<{ myTenantId: string | null }> = yield call(
    requestSaga,
    {
      operation: /* GraphQL */ `
        query MyTenantId {
          myTenantId
        }
      `,
      name: loadTenantIdRequestName,
    }
  );

  if (response && response.data?.myTenantId) {
    yield put(actions.loadedTenantId(response.data.myTenantId));
  }
}

export const saga = function* () {
  yield takeLatest(actions.loadRole.type, loadRoleSaga);
  yield takeLatest(actions.loadTenantId.type, loadTenantIdSaga);
  yield takeLatest(actions.create.type, createUserSaga);
  yield takeLatest(actions.loadProfile.type, loadUserProfileSaga);
  yield takeLatest(actions.updateProfile.type, updateUserProfileSaga);
};
